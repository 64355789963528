const baseUrl = window.location.protocol + "//" + window.location.host;

export const msalConfig = {
    // auth: {
    //   clientId: '7aa61b97-f26d-4796-9f12-f46f9865bcb6',
    //   authority: 'https://login.microsoftonline.com/f2a5032c-bc48-41e3-b4a0-918af16b8ade',
    //   redirectUri: baseUrl + '/authenticate',
    // },
    // auth: {
    //   clientId: '27a880c7-ff80-4c69-ad2f-313b4b3a36ca',
    //   // This should be the OAuth 2.0 / OpenID Connect authority URL
    //   authority: 'https://login.microsoftonline.com/3c4a7140-0c25-42c5-92d5-f545d6f2c554/v2.0',
    //   redirectUri: baseUrl + '/authenticate', // Production redirect URI
    // },
    auth: {
      clientId: '4ed48230-f30e-4614-a863-d2e513cad671',
      // This should be the OAuth 2.0 / OpenID Connect authority URL
      authority: 'https://login.microsoftonline.com/3c4a7140-0c25-42c5-92d5-f545d6f2c554/v2.0',
      redirectUri: baseUrl + '/authenticate', // Production redirect URI
    },    
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
          loggerCallback(logLevel, message, containsPii) {
              // console.log(message);
          },
          logLevel: "Verbose",
      }
  }
};
  
export const loginRequest = {
  scopes: ["openid", "profile", "User.Read"]
}
<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class=" grey--text text-large">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="pa-4 black--text"
        v-html="message"
      ></v-card-text>
      <v-row>
        <v-responsive
          class="ml-7 mt-2"
          max-width="390"
        >
          <v-text-field
            :rules="[rules.required]"
            v-model="ticket_note"
            label="Ticket Note (*)"
            placeholder="Enter ticket note here for the change"
            density="compact"
          ></v-text-field>
        </v-responsive>
      </v-row>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!options.noconfirm"
          color="grey"
          text
          class="body-2 font-weight-bold"
          @click.native="cancel"
          >Cancel</v-btn
        >
        <v-btn
          color="primary"
          class="body-2 font-weight-bold"
          outlined
          @click.native="agree"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/utils'
export default {
  name: 'ConfirmDlg',
  data () {
    return {
      rules: {
        required: value => !!value || 'Field is required'
      },
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: 'grey lighten-3',
        width: 500,
        zIndex: 200,
        noconfirm: false
      },
      ticket_note: ''
    }
  },
  methods: {
    open (title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async agree () {
      if (this.ticket_note !== '') {
        EventBus.emit('ticket_note_input', { ticket_note: this.ticket_note })
        await new Promise(resolve => setTimeout(resolve, 300))
        this.resolve(true)
        this.dialog = false
        this.ticket_note = ''
      }
    },
    cancel () {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>

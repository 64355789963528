<template>
  <v-app class="pt-0 mt-0 pl-0 ml-0" id='AdrMainWorkflow'>
   <div class="bg">
   <v-content class="pt-0 mt-0 pl-0 ml-0">
      <v-container class="pa-0 ma-0" fluid>
        <v-card class="mt-0 pt-0">
          <v-toolbar density="compact">
            <template v-slot:extension>
              <v-tabs
                v-model="tab"
                align-tabs="title"
              >
                <v-tab
                  v-for="item in items"
                  :key="item"
                  :value="item"
                  @click="getDevice(item)"
                >
                  {{ item }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>

          <v-window v-model="tab">
            <v-window-item
              v-for="item in items"
              :key="item"
              :value="item"
            >
              <v-card flat>
                <div v-if="item == 'All'" class="table-container">
                  <v-card elevation="5">
                    <v-card-title class="mb-0 pb-0 pt-0 mt-0">
                        <!-- DropDown Combo -->
                        <v-row>
                          <v-col>
                            <v-autocomplete class="mb-0 mt-1 ml-1"
                              :items='customers'
                              label='Select Customer'
                              persistent-hint
                              return-object
                              height="20"
                              single-line
                              hide-details
                              density="compact"
                              @update:modelValue='changeCustomer'>
                            </v-autocomplete>
                          </v-col>
                          <v-col></v-col>
                          <v-col></v-col>
                        </v-row>
                    </v-card-title>
                  </v-card>
                </div>
                <div v-if="item == 'HCS'">
                  <v-card elevation="5">
                    <v-card-title class="mb-0 pb-0 pt-0 mt-0">
                        <!-- DropDown Combo -->
                        <v-row>
                          <v-col>
                            <v-autocomplete class="mb-0 mt-1 ml-1"
                              :items='customers'
                              label='Select Customer'
                              persistent-hint
                              return-object
                              height="20"
                              single-line
                              hide-details
                              density="compact"
                              @update:modelValue='changeCustomer'>
                            </v-autocomplete>
                          </v-col>
                          <v-col></v-col>
                          <v-col></v-col>
                        </v-row>
                    </v-card-title>
                  </v-card>
                </div>
                <div v-if="item == 'HCS Fedramp'">
                  <v-card elevation="5">
                    <v-card-title class="mb-0 pb-0 pt-0 mt-0">
                        <!-- DropDown Combo -->
                        <v-row>
                          <v-col>
                              <v-autocomplete class="mb-0 mt-1 ml-1"
                                :items='customers'
                                label='Select Customer'
                                persistent-hint
                                return-object
                                height="20"
                                single-line
                                hide-details
                                density="compact"
                                @update:modelValue='changeCustomer'>
                              </v-autocomplete>
                          </v-col>
                          <v-col></v-col>
                          <v-col></v-col>
                        </v-row>
                    </v-card-title>
                  </v-card>
                </div>
                <div v-if="item == 'WxCCE'">
                  <v-card elevation="5">
                    <v-card-title class="mb-0 pb-0 pt-0 mt-0">
                        <!-- DropDown Combo -->
                        <v-row>
                          <v-col>
                            <v-autocomplete class="mb-0 mt-1 ml-1"
                              :items='customers'
                              label='Select Customer'
                              persistent-hint
                              return-object
                              height="20"
                              single-line
                              hide-details
                              density="compact"
                              @update:modelValue='changeCustomer'>
                            </v-autocomplete>
                          </v-col>
                          <v-col></v-col>
                          <v-col></v-col>
                        </v-row>
                    </v-card-title>
                  </v-card>
                </div>
                <div v-if="item == 'WxCCE Fedramp'">
                  <v-card elevation="5">
                    <v-card-title class="mb-0 pb-0 pt-0 mt-0">
                        <!-- DropDown Combo -->
                        <v-row>
                          <v-col>
                            <v-autocomplete class="mb-0 mt-1 ml-1"
                              :items='customers'
                              label='Select Customer'
                              persistent-hint
                              return-object
                              height="20"
                              single-line
                              hide-details
                              density="compact"
                              @update:modelValue='changeCustomer'>
                            </v-autocomplete>
                          </v-col>
                          <v-col></v-col>
                          <v-col></v-col>
                        </v-row>
                    </v-card-title>
                  </v-card>
                </div>
                <div v-if="item == 'Premise'">
                  <v-card elevation="5">
                    <v-card-title class="mb-0 pb-0 pt-0 mt-0">
                        <!-- DropDown Combo -->
                        <v-row>
                          <v-col>
                            <v-autocomplete class="mb-0 mt-1 ml-1"
                              :items='customers'
                              label='Select Customer'
                              persistent-hint
                              return-object
                              height="20"
                              single-line
                              hide-details
                              density="compact"
                              @update:modelValue='changeCustomer'>
                            </v-autocomplete>
                          </v-col>
                          <v-col></v-col>
                          <v-col></v-col>
                        </v-row>
                    </v-card-title>
                  </v-card>
                </div>
              </v-card>
            </v-window-item>
          </v-window>
        </v-card>
      <div>
       <v-card>
        <v-card-title class="mb-0 pb-0 pt-0 mt-1">
            <v-row no-gutters>
              <v-col>
                <v-text-field class="custom-text-field mb-1 mt-1" v-model="search_devices" append-icon="mdi-magnify"
                  label=""
                  placeholder="Search"
                  filled
                  rounded
                  density="compact"
                  single-line
                  hide-details>
                </v-text-field>
              </v-col>
              <v-col>
                <v-btn
                    class="ml-9 mt-2 ciscoblue"
                    prepend-icon="mdi-lan-connect"
                    small
                    dark
                    @click="toggleShowClientConn"
                  >
                  <template v-slot:prepend>
                    <v-icon color="success"></v-icon>
                  </template>
                  {{ showCustConn ? 'Hide Customer Connection' : 'Show Customer Connection' }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn  prepend-icon="mdi-file-download" small class="ml-0 mt-2 ciscoblue" dark @click="exportExcel()">
                  <template v-slot:prepend>
                    <v-icon color="success"></v-icon>
                  </template>
                  Export
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>    
            <div v-if='showCustConn' class="cui mt-0 ml-0 mb-0 mr-9">
                <v-container class="ttec">
                  <v-alert
                    color="blue-grey"
                    dark
                    dense
                    class="ma-1 pa-2"
                    elevation="20"
                  >
                  Customer Connectivity Information
                  </v-alert>
                  <v-row no-gutters>
                    <v-col class="mr-8"><v-chip class="mb-1 text-bold">Connection Type</v-chip><v-text-field density="compact" class="mt-0 pa-0" hide-details background-color="grey lighten-2" readonly solo v-model="cc_type"></v-text-field></v-col>
                    <v-col><v-chip class="mb-1 text-bold">Connection IP/Host</v-chip><v-text-field density="compact" class="mt-0 pa-0" hide-details background-color="grey lighten-2" readonly solo v-model="cc_host"></v-text-field></v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="mr-8 mt-1"><v-chip class="mb-1 text-bold">SharePoint URL</v-chip><v-text-field density="compact" class="mt-0 pa-0"   hide-details background-color="grey lighten-2" readonly solo v-model="cc_sharepoint"></v-text-field></v-col>
                    <v-col class="mt-1"><v-chip  class="mb-1 text-bold">FOB URL</v-chip><v-text-field density="compact" class="mt-0 pa-0" hide-details background-color="grey lighten-2" readonly solo v-model="cc_fob"></v-text-field></v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>
                      <p class="ttec_left" style="margin-top:2px; border: 1px solid #888888;border-radius: 8px; background-color: #dddddd; font-size: 1.6em; padding-left: 0.6em;" v-html="cc_detail"></p>
                    </v-col>
                  </v-row>
                </v-container>
            </div>
            <v-data-table
               v-model:expanded="expanded"
              :headers="header_devices"
              :items="deviceEntries"
              item-value="device_id"
              density="compact"
              class = "ttec_table cui"
              show-expand
              loading-text="Loading ..."
              @click:row="clickRow"
              :search="search_devices"
              >
                <template v-slot:[`item.device_name`]="{ item }">
                    <a href="javascript:;">{{ item.device_name }}</a>
                </template>
                <template v-slot:expanded-row="{ columns, item }">
                  <tr>
                    <td :colspan="10">
                    <DeviceDetail :deviceID="item.device_id"
                                  :deviceName="item.device_name"
                                  :deviceDesc="item.device_desc"
                                  :deviceType="item.dev_type_name"
                                  :deviceSubType="item.device_sub_type"
                                  :deviceIpType1="item.device_addr1_type"
                                  :deviceIp1="item.device_addr1"
                                  :deviceIpType2="item.device_addr2_type"
                                  :deviceIp2="item.device_addr2"
                                  :deviceSerialNum="item.device_serial_num"
                                  :deviceLocation="item.device_location"
                                  :deviceAccessInfo="item.access_info"
                                  />
                  </td>
                  </tr>
                </template>
            </v-data-table>
          </v-card>
          </div>
      </v-container>
    </v-content>
    <v-text-field
            model-value=""
            label=""
            variant="solo"
            readonly
          ></v-text-field>
    </div>
  </v-app>
</template>

<script>
import { EventBus } from '@/utils'
import DeviceDetail from './DeviceDetail'
import * as XLSX from 'xlsx'

export default {
  name: 'AdrMainWorkflow',
  components: {
    DeviceDetail
  },
  props: {
    title: String,
    deviceID: String,
    deviceName: String,
    deviceDesc: String,
    deviceType: String,
    deviceSubType: String,
    deviceIpType1: String,
    deviceIp1: String,
    deviceIpType2: String,
    deviceIp2: String,
    deviceSerialNum: String,
    eviceLocation: String,
    deviceAccessInfo: String
  },

  data: () => ({
    tab: null,
    items: [
      'All', 'HCS', 'HCS Fedramp', 'WxCCE', 'WxCCE Fedramp','Premise'
    ],
    expanded: [],
   
    loadDevTable: false,
    search_devices: '',
    deviceEntries: [],
    header_devices: [],
    columns_devices_export: [],
    customers: [],
    selectedCustomer: '',
    showCustConn: false,
    cc_type: '',
    cc_host: '',
    cc_sharepoint: '',
    cc_fob: '',
    cc_detail: '<h6>Connectivity Detail</h6><br/><a target="_blank" href="https://www.ttec.com/">TTEC Support</a>'
  }),

  mounted () {
    EventBus.on('device_return', (payload) => {
      this.loadDevTable = false
      this.search_devices = ''
      this.deviceEntries = payload.devices
      if (payload.update_clients) {
        this.customers = payload.customers
      }
    })
    EventBus.on('wxccedevice_return', (payload) => {
      this.loadDevTable = false
      this.search_devices = ''
      this.deviceEntries = payload.devices
      this.customers = payload.customers
    })
    EventBus.on('hcsdevice_return', (payload) => {
      this.loadDevTable = false
      this.search_devices = ''
      this.deviceEntries = payload.devices
      this.customers = payload.customers
    })
    EventBus.on('hcsfeddevice_return', (payload) => {
      this.loadDevTable = false
      this.search_devices = ''
      this.deviceEntries = payload.devices
      this.customers = payload.customers
    })
    EventBus.on('wxfeddevice_return', (payload) => {
      this.loadDevTable = false
      this.search_devices = ''
      this.deviceEntries = payload.devices
      this.customers = payload.customers
    })
    EventBus.on('premisedevice_return', (payload) => {
      this.loadDevTable = false
      this.search_devices = ''
      this.deviceEntries = payload.devices
      this.customers = payload.customers
    })
    EventBus.on('CC_return', (payload) => {
      this.cc_sharepoint = payload.share_point_link
      this.cc_fob = payload.fob_link
      this.cc_host = payload.server_ip
      this.cc_type = payload.connection_type
      this.cc_detail = payload.ccInfo_rtf
    })
  },

  created () {
    this.$store.dispatch('getDeviceData', { matrix: 'all', customer: '' })
    this.loadDevTable = true
    this.header_devices = [
      {
        title: 'Customer',
        align: 'start',
        sortable: false,
        key: 'cust_name'
      },
      { title: 'Device Type', key: 'dev_type_name' },
      { title: 'Sub Type', key: 'device_sub_type' },
      { title: 'Device', key: 'device_name' },
      { title: 'Description', key: 'device_desc' },
      { title: 'IP Addr1', key: 'device_addr1' },
      { title: 'Addr1 Type', key: 'device_addr1_type' },
      { title: 'IP Addr2', key: 'device_addr2' },
      { title: 'Addr2 Type', key: 'device_addr2_type' },
      { title: 'Location', key: 'device_location' }
    ]
    this.columns_devices_export = [
      {
        label: 'customer',
        field: 'cust_name'
      },
      {
        label: 'device name',
        field: 'device_name'
      },
      {
        label: 'device desc',
        field: 'device_desc'
      },
      {
        label: 'device serial',
        field: 'device_serial_num'
      },
      {
        label: 'device location',
        field: 'device_location'
      },
      {
        label: 'device type',
        field: 'dev_type_name'
      },
      {
        label: 'device subtype',
        field: 'device_sub_type'
      },
      {
        label: 'credential uname 1',
        field: 'cred1_username'
      },
      {
        label: 'credential passwd1',
        field: 'cred1_password'
      },
      {
        label: 'credential desc 1',
        field: 'device_credential1_description'
      },
      {
        label: 'credential uname 2',
        field: 'cred2_username'
      },
      {
        label: 'credential passwd2',
        field: 'cred2_password'
      },
      {
        label: 'credential desc 2',
        field: 'device_credential2_description'
      },
      {
        label: 'credential uname 3',
        field: 'cred3_username'
      },
      {
        label: 'credential passwd3',
        field: 'cred3_password'
      },
      {
        label: 'credential desc 3',
        field: 'device_credential3_description'
      },
      {
        label: 'credential uname 4',
        field: 'cred4_username'
      },
      {
        label: 'credential passwd4',
        field: 'cred4_password'
      },
      {
        label: 'credential desc 4',
        field: 'device_credential4_description'
      },
      {
        label: 'credential uname 5',
        field: 'cred5_username'
      },
      {
        label: 'credential passwd5',
        field: 'cred5_password'
      },
      {
        label: 'credential desc 5',
        field: 'device_credential5_description'
      },
      {
        label: 'device addr 1',
        field: 'device_addr1'
      },
      {
        label: 'device addr type 1',
        field: 'device_addr1_type'
      },
      {
        label: 'device addr 2',
        field: 'device_addr2'
      },
      {
        label: 'device addr type 2',
        field: 'device_addr2_type'
      },
      {
        label: 'device addr 3',
        field: 'device_addr3'
      },
      {
        label: 'device addr type 3',
        field: 'device_addr3_type'
      },
      {
        label: 'device addr 4',
        field: 'device_addr4'
      },
      {
        label: 'device addr type 4',
        field: 'device_addr4_type'
      },
      {
        label: 'device addr 5',
        field: 'device_addr5'
      },
      {
        label: 'device addr type 5',
        field: 'device_addr5_type'
      },
      {
        label: 'device access info',
        field: 'access_info'
      },
      {
        label: 'notification group',
        field: 'notification_group'
      },
      {
        label: 'notes',
        field: 'notes'
      },
      {
        label: 'monitor_status',
        field: 'monitor_status'
      }
    ]
  },

  methods: {    
    getDevice (argument) {
      this.selectedCustomer = ''
      if (argument == 'All') {
        this.$store.dispatch('getDeviceData', { matrix: 'all', customer: '' })
      }
      else if (argument == 'HCS') {
        this.$store.dispatch('getHcsDeviceData', { matrix: 'hcs', customer: '' })
      }
      else if (argument == 'WxCCE') {
        this.$store.dispatch('getWxcceDeviceData', { matrix: 'wxcce', customer: '' })
      }
      else if (argument == 'HCS Fedramp') {
        this.$store.dispatch('getHcsFedDeviceData', { matrix: 'hcs_fed', customer: '' })
      }
      else if (argument == 'WxCCE Fedramp') {
        this.$store.dispatch('getWxFedDeviceData', { matrix: 'wxcce_fed', customer: '' })
      }
      else if (argument == 'Premise') {
        this.$store.dispatch('getPremiseDeviceData', { matrix: 'premise', customer: '' })
      }
      this.loadDevTable = true
    },
    changeCustomer (value) {

      this.selectedCustomer = value

      this.$store.dispatch('getDeviceData', { matrix: 'all', customer: value })
      this.$store.dispatch('getCustConnInfo', { customer: value })
    },
    toggleShowClientConn () {
      this.showCustConn = !this.showCustConn
    },
    exportExcel () {
      let createXLSLFormatObj = []
      let newXlsHeader = []
      if (this.columns_devices_export.length === 0) {
        // console.log("Add columns!")
        return
      }

      if (this.deviceEntries.length === 0) {
        // console.log("Add data!")
        return
      }

      this.columns_devices_export.map(column => {
        newXlsHeader.push(column.label)
      })

      createXLSLFormatObj.push(newXlsHeader)
      this.deviceEntries.map(value => {
        let innerRowData = []
        this.columns_devices_export.map(val => {
          let fieldValue = value[val.field]
          if (val.field.split('.').length > 1) {
            fieldValue = this.getNestedValue(value, val.field)
          }
          if (val.dataFormat && typeof val.dataFormat === 'function') {
            innerRowData.push(val.dataFormat(fieldValue))
          } else {
            innerRowData.push(fieldValue)
          }
        })
        createXLSLFormatObj.push(innerRowData)
      })

      let fileName = this.selectedCustomer + '_' + (new Date()).toISOString().slice(0, 10) + '.' + 'xlsx'
      let wsName = 'devices'

      let wb = XLSX.utils.book_new()
      let ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj)
      XLSX.utils.book_append_sheet(wb, ws, wsName)
      XLSX.writeFile(wb, fileName)
    },
    getNestedValue (object, string) {
      string = string.replace(/\[(\w+)\]/g, '.$1')
      string = string.replace(/^\./, '')
      let a = string.split('.')
      for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i]
        if (k in object) {
          object = object[k]
        } else {
          return
        }
      }
      return object
    },
    clickRow(event, row) {  // This event passes two arguments: the item, and the item slot data, including the index of the clicked row. Use this information to modify the this.expanded array which tracks all expanded rows:
      // console.log('this.expanded [before]:' + this.expanded)
      //console.log('row.isExpanded [before]:' + isExpanded(row.item))
      if (this.expanded.indexOf(row.item.device_id) >= 0) {
          this.expanded.splice(this.expanded.indexOf(row.item.device_id), 1);
      } else {
         this.expanded.push(row.item.device_id)
      }
      // console.log('this.expanded [after]:' + this.expanded)
    }
  }
}
</script>

<style lang="css" scoped>
.myDiv {
  background-color: rgba(208, 208, 208, 0.83);
}

.btn {
  text-transform: unset !important;
}

p.solid {
  border: 1px solid #888888;
  border-radius: 5px;
}

.ttec_table.v-data-table :deep(th) {
  font-size: 11px !important;
  font-weight: bold;
  background-color: #ede;
}

.ttec_table.v-data-table :deep(td) {
  font-size: 11px !important;
}

 .ttec :deep(.v-input__slot::before) {
  border-style: none !important;
}

.ttec_table.theme--light.v-data-table :deep(tbody tr:nth-of-type(even)) {
    background-color: rgba(0, 0, 0, .03);
}

.ttec_table.theme--dark.v-data-table :deep(tbody tr:nth-of-type(even)) {
    background-color: rgba(0, 0, 0, .5);
}

:deep(.v-text-field){
      font-size: 10px;
}

.ttec_left {
  text-align: left;
}

.custom-text-field :deep(.v-field__input) { 
  padding-top: 0; 
  padding-block: 0; 
  margin: 0;
  height: 8px !important;
  font-size: 14px;
  }
</style>

import axios from 'axios'

var API_URL
fetch('/config.json').then(res => res.json()).then(config => {
  // console.log(config)
  API_URL = config['API_URL']
})

export function getdbdata (DbName, jwt) {
  return axios.get(`${API_URL}/api/system/db/${DbName}`, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function authenticate (userData) {
  return axios.post(`${API_URL}/api/system/users/login`, userData, { headers: { 'Content-Type': 'application/json' } })
}

export function authenticate_sso (userData) {
  return axios.post(`${API_URL}/api/system/users/login_sso`, userData, { headers: { 'Content-Type': 'application/json' } })
}

export function refreshAuth (userData) {
  let headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + userData }
  return axios.post(`${API_URL}/api/system/users/refresh`, '', { headers: headers })
}

export function register (userData) {
  // console.log('pass in api -register post call => ' + userData)
  return axios.post(`${API_URL}/api/system/users/register`, userData)
}

export function getWxcceData (queryData, jwt) {
  // process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
  return axios.post(`${API_URL}/api/device_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getDeviceData (queryData, jwt) {
  // process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
  return axios.post(`${API_URL}/api/device_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getAllDeviceData (queryData, jwt) {
  // process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'
  return axios.post(`${API_URL}/api/device_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getWxcceDeviceData (queryData, jwt) {
  return axios.post(`${API_URL}/api/device_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getHcsDeviceData (queryData, jwt) {
  return axios.post(`${API_URL}/api/device_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getHcsFedDeviceData (queryData, jwt) {
  return axios.post(`${API_URL}/api/device_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getCustDevices (queryData, jwt) {
  return axios.post(`${API_URL}/api/device_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getWxFedDeviceData (queryData, jwt) {
  return axios.post(`${API_URL}/api/device_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}  

export function getPremiseDeviceData (queryData, jwt) {
  return axios.post(`${API_URL}/api/device_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
} 

export function getDeviceCredList (queryData, jwt) {
  return axios.post(`${API_URL}/api/credential_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getCustConnInfo (queryData, jwt) {
  return axios.post(`${API_URL}/api/customer_connectivity_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getAllCustConnInfo (queryData, jwt) {
  return axios.post(`${API_URL}/api/customer_connectivity_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getWxCustConnInfo (queryData, jwt) {
  return axios.post(`${API_URL}/api/customer_connectivity_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getHcsFedCustConnInfo (queryData, jwt) {
  return axios.post(`${API_URL}/api/customer_connectivity_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getCustomerData (queryData, jwt) {
  return axios.post(`${API_URL}/api/entity_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getDevTypeData (queryData, jwt) {
  return axios.post(`${API_URL}/api/entity_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getCredData (queryData, jwt) {
  // alert('getCredData is call in api index.js ->' + queryData)
  return axios.post(`${API_URL}/api/entity_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getAuditData (queryData, jwt) {
  return axios.post(`${API_URL}/api/entity_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getIssueData (queryData, jwt) {
  return axios.post(`${API_URL}/api/entity_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getAuditPerfData (queryData, jwt) {
  return axios.post(`${API_URL}/api/entity_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getAccessLogData (queryData, jwt) {
  return axios.post(`${API_URL}/api/entity_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function getAccesslogPerfData (queryData, jwt) {
  return axios.post(`${API_URL}/api/entity_data/pull`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function addCustomer (queryData, jwt) {
  return axios.post(`${API_URL}/api/customer/add`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function saveCustomer (queryData, jwt) {
  return axios.post(`${API_URL}/api/customer/save`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function deleteCustomer (queryData, jwt) {
  return axios.post(`${API_URL}/api/customer/delete`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function saveCustCC (queryData, jwt) {
  return axios.post(`${API_URL}/api/custConnectivity/save`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function addDeviceType (queryData, jwt) {
  return axios.post(`${API_URL}/api/device_type/add`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function saveDeviceType (queryData, jwt) {
  return axios.post(`${API_URL}/api/device_type/save`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function deleteDeviceType (queryData, jwt) {
  return axios.post(`${API_URL}/api/device_type/delete`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function addDevCred (queryData, jwt) {
  return axios.post(`${API_URL}/api/device_cred/add`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function saveDevCred (queryData, jwt) {
  return axios.post(`${API_URL}/api/device_cred/save`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function deleteDevCred (queryData, jwt) {
  return axios.post(`${API_URL}/api/device_cred/delete`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function setCredAccessLog (queryData, jwt) {
  return axios.post(`${API_URL}/api/device_cred/access`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function addDevice (queryData, jwt) {
  return axios.post(`${API_URL}/api/device/add`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function saveDevice (queryData, jwt) {
  return axios.post(`${API_URL}/api/device/save`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function deleteDevice (queryData, jwt) {
  return axios.post(`${API_URL}/api/device/delete`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function addDevInfoIssueRpt (queryData, jwt) {
  return axios.post(`${API_URL}/api/device/issue`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}

export function uploadClientDevices (queryData, jwt) {
  return axios.post(`${API_URL}/api/device/upload`, queryData, { headers: { Authorization: 'Bearer ' + jwt, 'Content-Type': 'application/json' } })
}
 
 

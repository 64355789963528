<template>
  <v-container class="container">
    <v-app id='AdrAdmin'>
        <NavBar></NavBar>
        <v-content class="pt-0 mt-0 pl-0 ml-0">
          <component v-bind:is="component" :key="componentKey" />
        </v-content>
        <!--Footer FrameWork-->
        <Footer></Footer>
        <!--SnackBar FrameWork-->
        <v-snackbar
            v-model="snackbar"
            :multi-line="multiLine"
            :color="snackbar_color"
            :top="'top'"
            :timeout="snackbar_timeout"
        >
            {{ snackbar_text }}
            <v-btn
            color="red"
            text
            @click="snackbar = false"
            >close
            </v-btn>
        </v-snackbar>
    </v-app>
  </v-container>
</template>

<script>
import NavBar from './NavBar'
import Footer from './Footer'
import Dashboard from './Dashboard'
import Admin from './Admin'
import { EventBus } from '@/utils'


export default {
  name: 'AdrAdmin',
  metaInfo: {
    title: 'Admin - ADR Portal - TTEC Digital'
  },
  props: {
    source: String
  },
  components: {
    NavBar,
    Footer,
    Dashboard,
    Admin
  },
  data: () => ({
    componentKey: 0,
    background: true,
    right: false,
    left: false,
    snackbar: false,
    multiLine: true,
    loginuser: '',
    snackbar_text: 'snackbar_text',
    snackbar_color: 'blue',
    snackbar_timeout: 6000,
    items: [
      { title: 'LogOut' }
    ],
    mini: true,
    computed: {
      bg () {
        return this.background ? '../assets/spaceballs.jpg' : undefined
      }
    },
    component: Admin
  }),
  methods: {
  },
  mounted () {
    if (!this.$store.getters.isAdminRole) {
      this.$store.dispatch('logout')
      this.$router.replace({ path: '/' })
    }

    EventBus.on('snackbar_msg', (msg) => {
      // console.log('Pop snackbar messaging ' + msg.message)
      this.snackbar_text = msg.message
      this.snackbar_color = msg.status
      this.snackbar = true
    })
  }
}

</script>

<style scoped>
</style>

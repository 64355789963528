// import Vue from 'vue'
import { TinyEmitter } from 'tiny-emitter'

export const EventBus = new TinyEmitter()

export function isValidJwt (jwt) {
  // console.log('Recieve isValidJwt = ' + jwt)
  if (!jwt || jwt.split('.').length < 3) {
    // console.log('isValidJwt - false')
    return false
  }
  // alert('check if now < exp')
  const data = JSON.parse(atob(jwt.split('.')[1]))
  const exp = new Date(data.exp * 1000) // JS deals with dates in milliseconds since epoch
  const now = new Date()
  // alert(now)
  // alert(exp)  return now < exp
  return now < exp // not use for wx011  => return true
}

<template>
  <RouterView /> 
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import LoginView from './components/LoginView.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld,
    // LoginView
  }
}

/*
.v-icon--size-x-small {
  font-size: calc(var(--v-icon-size-multiplier) * 1em) !important;
}

.v-icon--size-small {
  font-size: calc(var(--v-icon-size-multiplier) * 1.25em) !important;
}

.v-icon--size-default {
  font-size: calc(var(--v-icon-size-multiplier) * 1.5em) !important;
}

.v-icon--size-large {
  font-size: calc(var(--v-icon-size-multiplier) * 1.75em) !important;
}

.v-icon--size-large {
  font-size: calc(var(--v-icon-size-multiplier) * 2em) !important;
}
*/
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  /*color: #2c3e50; */
  margin-top: 1px;
}

.v-icon--size-x-small {
  font-size: calc(var(--v-icon-size-multiplier) * 1.2em) !important;
}

.v-icon--size-small {
  font-size: calc(var(--v-icon-size-multiplier) * 1.5em) !important;
}

.v-icon--size-default {
  font-size: calc(var(--v-icon-size-multiplier) * 2.0em) !important;
}

.v-icon--size-large {
  font-size: calc(var(--v-icon-size-multiplier) * 1.75em) !important;
}

.v-icon--size-large {
  font-size: calc(var(--v-icon-size-multiplier) * 2em) !important;
}
</style>

<template>
  <div class="cui">
    <div class="content-fluid">
      <main>
        <header class="header header--tall">
          <div class="header-bar container">
            <div class="header-bar__main">
              <div class="header-menus">
                <ul class="nav nav-tabs">
                  <li class="tab">
                    <a href="#">
                      <div class="tab__heading text-bold" title="Basic">Dashboard</div>
                    </a>
                  </li>
                </ul>
                <span class="text-small"></span>
                <br><span class="text-xsmall"></span>
              </div>
            </div>
            <div class="header-toolbar">
              <a href="javascript:;" class="btn btn--icon">
                <span class="icon-search"></span>
              </a>
              <a href="javascript:;" class="btn btn--icon">
                <span class="icon-alert"></span>
              </a>
              <a href="javascript:;" class="btn btn--icon">
                <span class="icon-cog"></span>
              </a>
            </div>
          </div>
         
          <div>
            <v-divider :thickness="40"></v-divider>
            <v-row>
              <v-col>
                <highcharts :options="chartOptionsAccess"></highcharts>
              </v-col>
              <v-col>
                <highcharts :options="chartOptionsAudit"></highcharts>
              </v-col>
            </v-row>
            <v-divider :thickness="40"></v-divider>
          </div>
        </header>
      </main>
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import { EventBus } from '@/utils'
export default ({
  name: 'Dashboard',
  components: {
    highcharts: Chart,
  },
  data () {
    return {
      accessDate: [],
      auditDate: [],
      chartOptionsAccess: {
        chart: {
          type: 'column'
        },
        title: {
          text: 'Device Credential Access Counts'
        },
        yAxis: {
          title: {
            text: 'Access Counts'
          }
        },
        xAxis: {
          categories: this.accessDate,
          title: {
            text: 'Date'
          },
        },
        legend: {
          /*layout: 'vertical',
          align: 'right',
          floating: true,
          verticalAlign: 'top',*/
          borderWidth: 1,
          backgroundColor: '#fff',
          shadow: true,
        },
        credits: {
          enabled: false,
        },
        series: []
      },
      chartOptionsAudit: {
        chart: {
          type: 'column'
        },
        title: {
          text: 'ADR Change Audit Counts'
        },
        yAxis: {
          title: {
            text: 'Change Audit Counts'
          }
        },
        xAxis: {
          categories: this.auditDate,
          title: {
            text: 'Date'
          },
        },
        legend: {
          /*layout: 'vertical',
          align: 'right',
          floating: true,
          verticalAlign: 'top',*/
          borderWidth: 1,
          backgroundColor: '#fff',
          shadow: true,
        },
        credits: {
          enabled: false,
        },
        series: []
      }
    }
  },
  created () {
    this.$store.dispatch('getAccesslogPerfData', { entity: 'access_log_perf' })
    this.$store.dispatch('getAuditPerfData', { entity: 'audit_perf' })
  },
  watch:{
    accessDate(){this.redraw()},
  },
  methods:{
    redraw(){
    }
  },
  mounted () {
    EventBus.on('accesslogperf_return', (payload) => {
      this.accessDate = []
      var dataArr = []
      for (let i = 0; i < payload.accessLogsPerf.length; i++) {
        var dataEntry = payload.accessLogsPerf[i]
        var item = [ dataEntry.dateLbl ,dataEntry.cnt]
        dataArr.push(item)
        this.accessDate.push(dataEntry.dateLbl)
      }     
      const s = {
        name: 'Device Credential Access Count',
        data: dataArr
      }
      this.chartOptionsAccess.xAxis.categories = this.accessDate
      this.chartOptionsAccess.series.push(s)
    })

    EventBus.on('auditperf_return', (payload) => {
      this.auditDate = []
      var dataArr = []
      for (let i = 0; i < payload.auditPerf.length; i++) {
        var dataEntry = payload.auditPerf[i]
        var item = [ dataEntry.dateLbl ,dataEntry.cnt]
        dataArr.push(item)
        this.auditDate.push(dataEntry.dateLbl)
      }     
      const s = {
        name: 'Change Audit Count',
        data: dataArr,
        color: '#b87333'
      }
      this.chartOptionsAudit.xAxis.categories = this.auditDate
      this.chartOptionsAudit.series.push(s)
    })
  }
})
</script>

<template>
  <v-app class="pt-0 mt-0 pl-0 ml-0" id='Admin'>
   <div class="bg">
   <v-content class="pt-0 mt-0 pl-0 ml-0">
    <v-container class="pa-0 ma-0" fluid>
        <v-card class="mt-0 pt-0">
         <ConfirmDlg ref="confirm" /> 

          <v-toolbar density="compact">
                <template v-slot:extension>
                  <v-tabs
                    v-model="tab"
                    align-tabs="title"
                  >
                    <v-tab
                      v-for="item in items"
                      :key="item"
                      :value="item"
                      @click="processTab(item)"
                    >
                      {{ item }}
                    </v-tab>
                  </v-tabs>
                </template>
          </v-toolbar>
          <v-window v-model="tab">
            <v-window-item
              v-for="item in items"
              :key="item"
              :value="item"
            >
               <v-card flat>
                <div v-if="item == 'Customer'" class="table-container">
                  <div class="table-container">
                    <v-card elevation="5">
                    <v-card-title class="ml-0 mb-2 pb-0 pt-0 mt-1 text-small">
                      <v-row no-gutters>
                        <v-col cols="3">
                          <v-text-field class="custom-text-field ml-0 mt-1" v-model="search_cust" append-icon="mdi-magnify"
                              label=""
                              placeholder="Search"
                              filled
                              rounded
                              density="compact"
                              single-line
                              hide-details>
                          </v-text-field>
                        </v-col>
                        <v-col cols="9">
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-data-table
                      :headers="header_cust"
                      :items="custEntries"
                      density="compact"
                      :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                      }"
                      :items-per-page="10"
                      item-key="pkid"
                      class="elevation-8 ttec_table"
                      :loading="loadCustomerTable"
                      loading-text="Loading ..."
                      :search="search_cust">
                     
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-row justify="space-around" density="compact">
                          <v-btn class="small mt-1" icon density="compact" title="Edit" v-if="custEditingId != item.pkid" @click="editCustomer(item)" >
                            <v-icon
                                small
                                class="mb-3"
                                color="blue darken-2"
                              >
                                mdi-pencil-box-outline
                            </v-icon>
                          </v-btn>
                          <v-btn class="small mt-1" icon density="compact" title="Save" v-if="custEditingId == item.pkid" @click="saveCustomer(item)" >
                            <v-icon
                                small
                                class="mb-3"
                                color="blue darken-2"
                              >
                                mdi-content-save
                            </v-icon>
                          </v-btn>
                          <v-btn class="small mt-1" icon density="compact" title="Cancel" v-if="custEditingId == item.pkid" @click="cancelSaveCustomer(item)" >
                            <v-icon
                               small
                               class="mb-3"
                               color="blue darken-2"
                            >
                              mdi-close-box
                            </v-icon>
                          </v-btn>
                          <v-btn class="small mt-1" icon density="compact" title="Delete" @click="deleteCustomer(item)" >
                            <v-icon
                              small
                              class="mb-3"
                              color="blue darken-2"
                            >
                              mdi-trash-can-outline
                            </v-icon>
                          </v-btn>
                        </v-row>
                      </template>
                      <template v-slot:[`item.cust_name`]="{ item }">
                        <div v-if="custEditingId == item.pkid">
                          <v-text-field  density="compact" hide-details="true" v-model="item.cust_name"></v-text-field>
                        </div>
                        <div v-else>{{ item.cust_name }}</div>
                      </template>
                      <template v-slot:[`item.snow_cust_account`]="{ item }">
                        <div v-if="custEditingId == item.pkid">
                          <v-text-field density="compact" hide-details="true"  v-model="item.snow_cust_account"></v-text-field>
                        </div>
                        <div v-else>{{ item.snow_cust_account }}</div>
                      </template>
                      <template v-slot:[`item.em7_active`]="{ item }">
                        <div v-if="custEditingId == item.pkid">
                          <v-checkbox
                            :false-value="0"
                            :true-value="1"
                            v-model="item.em7_active"
                          >
                          </v-checkbox>
                        </div>
                        <div v-else>
                          {{ item.em7_active }}
                        </div>
                      </template>
                      <template v-slot:[`item.external_api_enabled`]="{ item }">
                        <div v-if="custEditingId == item.pkid">
                          <v-checkbox
                            :false-value="0"
                            :true-value="1"
                            v-model="item.external_api_enabled"
                          >
                          </v-checkbox>
                        </div>
                        <div v-else>
                          {{ item.external_api_enabled }}
                        </div>
                      </template>
                      <template v-slot:[`item.active`]="{ item }">
                        <div v-if="custEditingId == item.pkid">
                          <v-checkbox
                            :false-value="0"
                            :true-value="1"
                            v-model="item.active"
                          >
                          </v-checkbox>
                        </div>
                        <div v-else>
                          {{ item.active }}
                        </div>
                      </template>
                    </v-data-table>
                    <v-card-actions>
                      <div v-if="!isEditingNewCust">
                        <v-btn  elevation="8" prepend-icon="mdi-plus" small class="ml-0 mt-1 ciscoblue" dark @click="addCustomer">
                                <template v-slot:prepend>
                                  <v-icon color="success"></v-icon>
                                </template>
                                Add Customer
                        </v-btn>
                      </div>
                      <div class="raisedbox text-small text-bold" v-else>
                        <v-row no-gutters>
                          <v-col class="pr-6">Customer Name:<v-text-field density="compact" v-model="newCustName"></v-text-field></v-col>
                          <v-col>SNOW Organization Name:<v-text-field density="compact" v-model="newCustSnowOrgName"></v-text-field></v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col>EM7 Active:<v-checkbox :false-value="0" :true-value="1" v-model="newCustEm7Active" density="compact"></v-checkbox></v-col>
                          <v-col>External API Enabled:<v-checkbox :false-value="0" :true-value="1" v-model="newCustExtApiEnabled" density="compact"></v-checkbox></v-col>
                          <v-col>ADR Active:<v-checkbox :false-value="0" :true-value="1" v-model="newCustAdrActive" density="compact"></v-checkbox></v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col>Ticket / Note:<v-text-field density="compact" v-model="newCustNotes"></v-text-field></v-col>
                        </v-row>
                        <v-btn elevation="10" class="mt-0 mr-2" small dark color="ciscoblue" @click="addNewCust">Add</v-btn>
                        <v-btn elevation="10" class="mt-0" small dark color="ciscoblue" @click="cancelNewCust">Cancel</v-btn>
                      </div>
                    </v-card-actions>
                  </v-card>
                  </div>
                </div>
                <div v-if="item == 'Customer Conn'" class="table-container">
                  <div class="table-container">
                      <v-card elevation="5">
                        <v-card-title class="ml-0 mb-2 pb-0 pt-0 mt-2 text-medium">
                          <v-row class="mt-1">
                            <v-col cols="4">
                              <v-autocomplete class="mb-0 mt-0 ml-5"
                                :items='customers'
                                label='Select Customer'
                                persistent-hint
                                return-object
                                height="40"
                                single-line
                                hide-details
                                density="compact"
                                @update:modelValue='changeCustomer'>
                              </v-autocomplete>
                            </v-col>
                            <v-col cols="1">
                            </v-col>
                            <v-col cols="2">
                              <v-btn  elevation="8" prepend-icon="mdi-content-save" small class="ml-0 mt-0 mb-1 ciscoblue" dark @click="saveCustCC">
                                <template v-slot:prepend>
                                  <v-icon color="success"></v-icon>
                                </template>
                                Save
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-container class="ttec">
                          <v-alert
                            border="right"
                            color="blue-grey"
                            dark
                            dense
                            elevation="20"
                            class="ma-1 pa-2"
                          >
                          Customer Connectivity Information
                          </v-alert>
                          <v-row no-gutters>
                            <v-col class="mr-8"><v-chip class="mb-1 text-bold">Connection Type</v-chip><v-text-field density="compact" class="mt-0 mb-2" hide-details background-color="grey lighten-2" solo v-model="cc_type"></v-text-field></v-col>
                            <v-col><v-chip class="mb-1 text-bold">Connection IP/Host</v-chip><v-text-field density="compact" class="mt-0 mb-2" hide-details background-color="grey lighten-2" solo v-model="cc_host"></v-text-field></v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col class="mr-8"><v-chip class="mb-1 text-bold">SharePoint URL</v-chip><v-text-field density="compact" class="mt-0"   hide-details background-color="grey lighten-2" solo v-model="cc_sharepoint"></v-text-field></v-col>
                            <v-col><v-chip class="mb-1 text-bold">FOB URL</v-chip><v-text-field density="compact" class="mt-0" hide-details background-color="grey lighten-2" solo v-model="cc_fob"></v-text-field></v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col>
                              <v-spacer></v-spacer>
                              <v-chip class="mb-1 mt-2 text-bold">Connectivity Detail</v-chip>
                              <ckeditor v-model="cc_richTextContent"></ckeditor>
                              <v-spacer></v-spacer>
                              HTML Coding: {{cc_richTextContent}}
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </div>
                  </div>
                  <div v-if="item == 'Device Types'" class="table-container">
                    <div class="table-container">
                      <v-card elevation="5">
                      <v-card-title class="ml-0 mb-2 pb-0 pt-0 mt-1 text-small">
                        <v-row no-gutters>
                          <v-col cols="3">
                            <v-text-field class="custom-text-field ml-0 mt-1" v-model="search_devType" append-icon="mdi-magnify"
                                label=""
                                placeholder="Search"
                                filled
                                rounded
                                density="compact"
                                single-line
                                hide-details>
                            </v-text-field>
                          </v-col>
                          <v-col cols="9">
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-data-table
                        :headers="header_devType"
                        :items="devTypeEntries"
                        density="compact"
                        :footer-props="{
                          'items-per-page-options': [10, 20, 30, 40, 50, -1]
                        }"
                        :items-per-page="10"
                        item-key="pkid"
                        class="elevation-8 ttec_table"
                        :loading="loadDevTypeTable"
                        loading-text="Loading ..."
                        :search="search_devType">
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-row justify="space-around" density="compact">
                            <v-btn class="small mt-1" icon density="compact" title="Edit" v-if="typeEditingId != item.pkid" @click="editDevType(item)" >
                              <v-icon
                                  small
                                  class="mb-3"
                                  color="blue darken-2"
                                >
                                  mdi-pencil-box-outline
                              </v-icon>
                            </v-btn>
                            <v-btn class="small mt-1" icon density="compact" title="Save" v-if="typeEditingId == item.pkid" @click="saveDevType(item)" >
                              <v-icon
                                  small
                                  class="mb-3"
                                  color="blue darken-2"
                                >
                                  mdi-content-save
                              </v-icon>
                            </v-btn>
                            <v-btn class="small mt-1" icon density="compact" title="Cancel" v-if="typeEditingId == item.pkid" @click="cancelSaveDevType(item)" >
                              <v-icon
                                small
                                class="mb-3"
                                color="blue darken-2"
                              >
                                mdi-close-box
                              </v-icon>
                            </v-btn>
                            <v-btn class="small mt-1" icon density="compact" title="Delete" @click="deleteDevType(item)" >
                              <v-icon
                                small
                                class="mb-3"
                                color="blue darken-2"
                              >
                                mdi-trash-can-outline
                              </v-icon>
                            </v-btn>
                          </v-row>
                        </template>
                        <template v-slot:[`item.dev_type_name`]="{ item }">
                          <div v-if="typeEditingId == item.pkid">
                            <v-text-field  v-model="item.dev_type_name" density="compact"></v-text-field>
                          </div>
                          <div v-else>{{ item.dev_type_name }}</div>
                        </template>
                        <template v-slot:[`item.dev_subtype`]="{ item }">
                          <div v-if="typeEditingId == item.pkid">
                            <v-text-field  v-model="item.dev_subtype" density="compact"></v-text-field>
                          </div>
                          <div v-else>{{ item.dev_subtype }}</div>
                        </template>
                        <template v-slot:[`item.dev_description`]="{ item }">
                          <div v-if="typeEditingId == item.pkid">
                            <v-text-field  v-model="item.dev_description" density="compact"></v-text-field>
                          </div>
                          <div v-else>{{ item.dev_description }}</div>
                        </template>
                        <template v-slot:[`item.timestamp`]="{ item }">
                          <span>{{ new Date(item.timestamp).toLocaleString('en-US') }}</span>
                        </template>
                      </v-data-table>
                      <v-card-actions>
                        <div v-if="!isEditingNewDevType">
                          <v-btn  elevation="8" prepend-icon="mdi-plus" small class="ml-0 mt-1 ciscoblue" dark @click="addDevType">
                                <template v-slot:prepend>
                                  <v-icon color="success"></v-icon>
                                </template>
                                Add Device Type
                          </v-btn>
                        </div>
                        <div class="raisedbox text-small text-bold" v-else>
                          <v-row no-gutters>
                            <v-col cols="2" class="half-margin-top">Type:</v-col><v-col cols="10"><v-text-field v-model="newTypeName" density="compact"></v-text-field></v-col>
                          </v-row>
                          <v-row no-gutters>
                          <v-col cols="2" class="half-margin-top">Sub Type:</v-col><v-col cols="10"><v-text-field v-model="newTypeSubType" density="compact"></v-text-field></v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col cols="2" class="half-margin-top">Description:</v-col><v-col cols="10"><v-text-field v-model="newTypeDesc" density="compact"></v-text-field></v-col>
                          </v-row>
                          <v-btn elevation="20" class="mr-2" small dark color="ciscoblue" @click="addNewDevType">Add</v-btn>
                          <v-btn elevation="20" small dark color="ciscoblue" @click="cancelNewDevType">Cancel</v-btn>
                        </div>
                      </v-card-actions>
                      </v-card>
                    </div>
                  </div>
                  <div v-if="item == 'Credentials'" class="table-container">
                    <div>
                      <v-card elevation="5">
                      <v-card-title class="ml-0 mb-2 pb-0 pt-0 mt-1 text-medium">
                          <v-row no-gutters>
                          <v-col cols="3">
                            <v-text-field class="custom-text-field ml-0 mt-1" v-model="search_cred" append-icon="mdi-magnify"
                                label=""
                                placeholder="Search"
                                filled
                                rounded
                                density="compact"
                                single-line
                                hide-details>
                            </v-text-field>
                          </v-col>
                          <v-col cols="9">
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-data-table  
                        table-striped
                        :expanded.sync="expandedCred"
                        :headers="header_credentials"
                        :items="credEntries"
                        item-value="pkid"
                        density="compact"
                        :single-expand="true"
                        class="elevation-8 ttec_table"
                        show-expand
                        :loading="loadCredTable"
                        loading-text="Loading ..."
                        :search="search_cred"
                        >
                        <template v-slot:expanded-row="{ item }">
                        <tr>
                         <td :colspan="8">
                          <CredEditDetail :credID="item.pkid"
                                          :credUserName="item.username"
                                          :credPassword="item.password"
                                          :credDesc="item.description"
                                          :credCust="item.cust"
                                          />
                          </td>
                        </tr>
                      </template>
                      </v-data-table>
                      <v-card-actions>
                        <div v-if="!isEditingNewCredential">
                          <v-btn  elevation="8" prepend-icon="mdi-plus" small class="ml-0 mt-1 ciscoblue" dark @click="addCredential">
                                <template v-slot:prepend>
                                  <v-icon color="success"></v-icon>
                                </template>
                                Add Credential
                          </v-btn>
                        </div>
                        <div class="raisedbox text-small text-bold" v-else>
                          <v-row>
                            <v-col class="pr-6">
                              <v-autocomplete class="mb-2 mt-0 ml-0 mr-6"
                                  item-title="name"
                                  item-value="pkid"
                                  :items="credCustomers"
                                  label='Select Customer Name'
                                  persistent-hint
                                  return-object
                                  height="40"
                                  single-line
                                  hide-details
                                  density="compact"
                                  v-model="selectedCredCust"
                                  >
                                </v-autocomplete>
                              </v-col>
                          </v-row>
                          <v-row no-gutters>
                          <v-col cols="4" class="half-margin-top">Username (Case Sensitive):</v-col><v-col cols="8"><v-text-field v-model="newCredUser" aria-autocomplete="false" density="compact" class="mr-8"></v-text-field></v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col cols="4" class="half-margin-top">Password (Case Sensitive):</v-col><v-col cols="8"><v-text-field :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" v-model="newCredPass" aria-autocomplete="false" density="compact"></v-text-field></v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col cols="4" class="half-margin-top">Confirm Password (Case Sensitive):</v-col><v-col cols="8"><v-text-field :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'text' : 'password'" @click:append="show2 = !show2" v-model="newCredPassConfirm" density="compact"></v-text-field></v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col cols="4" class="half-margin-top">Description:</v-col><v-col cols="8"><v-text-field v-model="newCredDesc" density="compact" class="mr-8"></v-text-field></v-col>
                          </v-row>
                          <v-btn elevation="20" class="mr-2" small dark color="ciscoblue" @click="addNewCredential">Add</v-btn>
                          <v-btn elevation="20" small dark color="ciscoblue" @click="cancelNewCredential">Cancel</v-btn>
                        </div>
                      </v-card-actions>
                      </v-card>
                    </div>
                  </div>
                  <div v-if="item == 'Devices'" class="table-container">
                    <div>
                        <v-card elevation="5">
                        <v-card-title class="ml-0 mb-2 pb-0 pt-0 mt-1 text-medium">
                            <v-row class="m-t-2" align="center">
                              <v-col cols="4">
                                <v-autocomplete class="mb-0 mt-0 ml-0"
                                  :items='customers'
                                  label='Select Customer'
                                  persistent-hint
                                  return-object
                                  height="40"
                                  single-line
                                  hide-details
                                  density="compact"
                                  @update:modelValue='changeDevCustomer'>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field class="custom-text-field ml-6 mt-2 mr-6" v-model="search_devices" append-icon="mdi-magnify"
                                  label=""
                                  placeholder="Search"
                                  filled
                                  rounded
                                  density="compact"
                                  single-line
                                  hide-details>
                                </v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-btn  :disabled="this.selectedCustomer === ''" elevation="8" prepend-icon="mdi-file-upload" small class="ml-0 mt-1 ciscoblue" dark @click="showModal">
                                    <template v-slot:prepend>
                                      <v-icon color="success"></v-icon>
                                    </template>
                                    Import
                                </v-btn>
                                
                                <Modal
                                    :deviceCust="this.selectedCustomer"
                                    v-show="isModalVisible"
                                    @close="closeModal"
                                  />
                              </v-col>
                            </v-row>
                        </v-card-title>
                        <v-data-table
                        v-model:expanded="expanded"
                        :headers="header_devices"
                        :items="deviceEntries"
                        item-value="device_id"
                        density="compact"
                        class = "ttec_table cui"
                        show-expand
                        @click:row="clickRow"
                        loading-text="Loading ..."
                        :search="search_devices"
                          >
                          <template v-slot:[`item.device_name`]="{ item }">
                              <a href="javascript:;">{{ item.device_name }}</a>
                          </template>
                          <template v-slot:expanded-row="{ columns, item }">
                            <tr>
                              <td :colspan="12">
                                <DeviceEditDetail
                                            :deviceID="item.device_id"
                                            :deviceName="item.device_name"
                                            :deviceDesc="item.device_desc"
                                            :deviceType="item.dev_type_name"
                                            :deviceSubType="item.device_sub_type"
                                            :deviceIpType1="item.device_addr1_type"
                                            :deviceIp1="item.device_addr1"
                                            :deviceIpType2="item.device_addr2_type"
                                            :deviceIp2="item.device_addr2"
                                            deviceIpType3=""
                                            deviceIp3=""
                                            deviceIpType4=""
                                            deviceIp4=""
                                            :deviceSerialNum="item.device_serial_num"
                                            :deviceLocation="item.device_location"
                                            :deviceTypeId="item.device_type_id"
                                            :dev_richTextContent="item.access_info"
                                            :deviceTypes="deviceTypes"
                                            :custCreds="custCreds"/>
                            </td>
                            </tr>
                          </template>
                        </v-data-table>
                        <v-card-actions v-if="this.selectedCustomer != ''">
                          <div v-if="!this.isEditingNewDev">
                            <v-btn elevation="20" small dark color="ciscoblue" @click="addDevice"><v-icon left>mdi-plus</v-icon>
                              Add Device
                            </v-btn>
                          </div>
                          <div v-if="this.isEditingNewDev">
                            <DeviceAddDetail :deviceCust="this.selectedCustomer"
                                            deviceName=""
                                            deviceDesc=""
                                            deviceType=""
                                            deviceSubType=""
                                            deviceIpType1="default"
                                            deviceIp1=""
                                            deviceIpType2=""
                                            deviceIp2=""
                                            deviceIpType3=""
                                            deviceIp3=""
                                            deviceIpType4=""
                                            deviceIp4=""
                                            cred1_id=""
                                            cred1_usage=""
                                            cred2_id=""
                                            cred2_usage=""
                                            cred3_id=""
                                            cred3_usage=""
                                            cred4_id=""
                                            cred4_usage=""
                                            deviceSerialNum=""
                                            deviceLocation=""
                                            deviceTypeId=""
                                            :deviceTypes="deviceTypes"
                                            :custCreds="custCreds"/>
                          </div>
                        </v-card-actions>
                        </v-card>
                      </div>
                  </div>
                  <div v-if="item == 'Access Logs'" class="table-container">
                    <div class="table-container">
                        <v-card elevation="5">
                        <v-card-title class="ml-0 mb-2 pb-0 pt-0 mt-1 text-small">
                          <v-row no-gutters>
                            <v-col cols="3">
                              <v-text-field class="custom-text-field ml-0 mt-1" v-model="search_accessLog" append-icon="mdi-magnify"
                                  label=""
                                  placeholder="Search"
                                  filled
                                  rounded
                                  density="compact"
                                  single-line
                                  hide-details>
                              </v-text-field>
                            </v-col>
                            <v-col cols="9">
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-data-table
                          :headers="header_accessLog"
                          :items="accessLogEntries"
                          density="compact"
                          :footer-props="{
                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                          }"
                          :items-per-page="10"
                          item-key="pkid"
                          class="elevation-8 ttec_table"
                          :loading="loadAccessTable"
                          loading-text="Loading ..."
                          :search="search_accessLog">
                          <template v-slot:[`item.timestamp`]="{ item }">
                            <span>{{ new Date(item.timestamp).toLocaleString('en-US') }}</span>
                          </template>
                        </v-data-table>
                        </v-card>
                      </div>
                  </div>
                  <div v-if="item == 'Audit Logs'" class="table-container">
                    <div class="table-container">
                        <v-card elevation="5">
                        <v-card-title class="ml-0 mb-2 pb-0 pt-0 mt-1 text-small">
                          <v-row no-gutters>
                            <v-col cols="3">
                              <v-text-field class="custom-text-field ml-0 mt-1" v-model="search_audit" append-icon="mdi-magnify"
                                  label=""
                                  placeholder="Search"
                                  filled
                                  rounded
                                  density="compact"
                                  single-line
                                  hide-details>
                              </v-text-field>
                            </v-col>
                            <v-col cols="9">
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-data-table
                          :headers="header_audit"
                          :items="auditEntries"
                          density="compact"
                          :footer-props="{
                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                          }"
                          :items-per-page="10"
                          item-key="pkid"
                          class="elevation-8 ttec_table"
                          :loading="loadAuditLogsTable"
                          loading-text="Loading ..."
                          :search="search_audit">
                          <template v-slot:[`item.timestamp`]="{ item }">
                            <span>{{ new Date(item.timestamp).toLocaleString('en-US') }}</span>
                          </template>
                        </v-data-table>
                      </v-card>
                    </div>
                  </div>
                  <div v-if="item == 'Issue Report'" class="table-container">
                    <div class="table-container">
                        <v-card elevation="5">
                        <v-card-title class="ml-0 mb-2 pb-0 pt-0 mt-1 text-small">
                          <v-row no-gutters>
                            <v-col cols="3">
                              <v-text-field class="custom-text-field ml-0 mt-1" v-model="search_issue" append-icon="mdi-magnify"
                                  label=""
                                  placeholder="Search"
                                  filled
                                  rounded
                                  density="compact"
                                  single-line
                                  hide-details>
                              </v-text-field>
                            </v-col>
                            <v-col cols="9">
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-data-table
                          :headers="header_issue"
                          :items="issueEntries"
                          density="compact"
                          :footer-props="{
                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                          }"
                          :items-per-page="10"
                          item-key="id"
                          class="elevation-8 ttec_table"
                          :loading="loadIssuesTable"
                          loading-text="Loading ..."
                          :search="search_issue">
                          <template v-slot:[`item.notes`]="{ item }">
                            <a href="javascript:;">{{ item.notes }}</a>
                        </template>
                        </v-data-table>
                      </v-card>
                    </div>
                  </div>
               </v-card>
            </v-window-item>
          </v-window>
        </v-card>
        <v-snackbar
                        v-model="hasSaved"
                        :timeout="3000"
                        attach
                        position="absolute"
                        location="top right"
                        >
                        {{ this.snackbar_msg }}
        </v-snackbar>
        <br/><br/>
      </v-container>
    </v-content>
    </div>
  </v-app>
</template>

<script>
import { EventBus } from '@/utils'
import ConfirmDlg from './ConfirmDlg'
import { v4 as uuidv4 } from 'uuid'
import DeviceEditDetail from './DeviceEditDetail'
import CredEditDetail from './CredEditDetail'
import DeviceAddDetail from './DeviceAddDetail'
import Modal from './Modal'
import { useToast } from 'vue-toastification';

export default {
  name: 'Admin',
  components: {
    ConfirmDlg,
    DeviceEditDetail,
    CredEditDetail,
    DeviceAddDetail,
    Modal
  },
  data: () => ({
    search_cust: '',
    search_devType: '',
    search_cred: '',
    search_audit: '',
    search_issue: '',
    search_accessLog: '',
    loadCustomerTable: false,
    loadDevTypeTable: false,
    loadCredTable: false,
    loadAuditLogsTable: false,
    loadIssuesTable: false,
    loadAccessTable: false,
    custEntries: [],
    devTypeEntries: [],
    credEntries: [],
    deviceEntries: [],
    deviceTypes: [],
    accessLogEntries: [],
    auditEntries: [],
    issueEntries: [],
    header_cust: [],
    header_devType: [],
    header_credentials: [],
    header_audit: [],
    header_issue: [],
    header_accessLog: [],
    custEditingId: '',
    cust_name_preSave: '',
    snow_cust_account_preSave: '',
    em7_active_preSave: null,
    external_api_enabled_preSave: null,
    active_preSave: null,
    isEditingNewCust: false,
    isEditingNewDevType: false,
    isEditingNewDev: false,
    isEditingNewCredential: false,
    newCustName: '',
    newCustSnowOrgName: '',
    newCustEm7Active: 0,
    newCustExtApiEnabled: 0,
    newCustAdrActive: 0,
    newCustNotes: '',
    customers: [],
    selectedCustomer: '',
    cc_pkid: '',
    cc_type: '',
    cc_host: '',
    cc_sharepoint: '',
    cc_fob: '',
    cc_richTextContent: `<h6>Connectivity Detail</h6><br/><a target="_blank" href="https://www.ttec.com/">TTEC Support</a>`,
    typeEditingId: '',
    type_preSave: '',
    typeSubType_preSave: '',
    typeDesc_preSave: '',
    newTypeName: '',
    newTypeSubType: '',
    newTypeDesc: '',
    loadDevTable: false,
    search_devices: '',
    header_devices: [],
    columns_devices: [],
    credCustomers: [],
    selectedCredCust: null,
    newCredUser: '',
    newCredPass: '',
    newCredPassConfirm: '',
    newCredDesc: '',
    show1: false,
    show2: false,
    expanded: [],
    expandedCred: [],
    hasSaved: false,
    snackbar_msg: 'Record has been updated',
    isModalVisible: false,
    ticket_note: '',
    tab: null,
    items: [
      'Customer', 'Customer Conn', 'Device Types', 
      'Credentials', 'Devices', 'Access Logs',
      'Audit Logs' , 'Issue Report'
    ],
  }),

  mounted () {
    const toast = useToast()
    EventBus.on('addCustomer_return', (payload) => {
      if (payload.status === 'OK') {
        this.$store.dispatch('getCustomerData', { entity: 'customer' })
        this.loadCustomerTable = true
      } else {
        // alert('Falied to add new customer: ' + payload.status + ' Please contact portal administrator.')
        toast.error('Falied to add new customer: ' + payload.status + ' Please contact portal administrator.')
      }
    })
    EventBus.on('saveCustomer_return', (payload) => {
      if (payload.status === 'OK') {
        this.$store.dispatch('getCustomerData', { entity: 'customer' })
        this.loadCustomerTable = true
      } else {
        // alert('Falied to save customer: ' + payload.status + ' Please contact portal administrator.')
        toast.error('Falied to save customer: ' + payload.status + ' Please contact portal administrator.')
      }
    })
    EventBus.on('deleteCustomer_return', (payload) => {
      if (payload.status === 'OK') {
        this.$store.dispatch('getCustomerData', { entity: 'customer' })
        this.loadCustomerTable = true
      } else {
        // alert('Falied to delete customer: ' + payload.status + ' Please contact portal administrator.')
        toast.error('Falied to delete customer: ' + payload.status + ' Please contact portal administrator.')
      }
    })
    EventBus.on('customers_return', (payload) => {
      this.loadCustomerTable = false
      this.search_cust = ''
      this.custEntries = payload.customers
      this.header_cust = [
        {
          // align: 'start',
          // sortable: true,
          key: 'cust_name',
          title: 'Customer',
        },
        {  key: 'snow_cust_account' ,title: 'Snow Cust Account'},
        {  key: 'em7_active', title: 'EM7 Active' },
        {  key: 'external_api_enabled',title: 'External API Enabled' },
        {  key: 'active', title: 'Active' },
        {  key: 'actions', title: 'Actions', sortable: false }
      ]
    })
    EventBus.on('devType_return', (payload) => {
      this.loadDevTypeTable = false
      this.search_devType = ''
      this.devTypeEntries = payload.devTypes
      this.header_devType = [
        {
          title: 'Device Type',
          align: 'start',
          sortable: true,
          key: 'dev_type_name'
        },
        { title: 'Sub Type', key: 'dev_subtype' },
        { title: 'Description', key: 'dev_description' },
        { title: 'Devices Used', key: 'used' },
        { title: 'Updated By', key: 'updated_by' },
        { title: 'Timestamp', key: 'timestamp' },
        { title: 'Actions', key: 'actions', sortable: false }
      ]
    })
    EventBus.on('credentials_return', (payload) => {
      // alert('credentials_return detected !')
      this.loadCredTable = false
      this.search_cred = ''
      this.credEntries = payload.credentials
      this.credCustomers = payload.customerList
      this.header_credentials = [
        {
          title: 'Customer Name',
          align: 'start',
          sortable: true,
          key: 'cust'
        },
        { title: 'Username', key: 'username' },
        { title: 'Description', key: 'description' },
        { title: 'Total Usage', key: 'used' }
      ]
    })
    EventBus.on('audit_return', (payload) => {
      this.loadAuditLogsTable = false
      this.search_audit = ''
      this.auditEntries = payload.audits
      this.header_audit = [
        {
          title: 'Action',
          align: 'start',
          sortable: true,
          key: 'action'
        },
        { title: 'Customer', key: 'customer' },
        { title: 'Old Device Name', key: 'old_device_name' },
        { title: 'New Device Name', key: 'new_device_name' },
        { title: 'Old IP', key: 'old_ip' },
        { title: 'New IP', key: 'new_ip' },
        { title: 'Updated By', key: 'updated_by' },
        { title: 'Timestamp', key: 'timestamp' },
        { title: 'Ticket Note', key: 'ticket_note' }
      ]
    })
    EventBus.on('accesslog_return', (payload) => {
      this.loadAccessTable = false
      this.search_accessLog = ''
      this.accessLogEntries = payload.accessLogs
      this.header_accessLog = [
        {
          title: 'ID',
          align: 'start',
          sortable: true,
          key: 'pkid'
        },
        { title: 'Customer', key: 'organization' },
        { title: 'Device', key: 'device' },
        { title: 'User', key: 'username' },
        { title: 'Timestamp', key: 'timestamp' }
      ]
    })
    EventBus.on('issue_return', (payload) => {
      this.loadIssuesTable = false
      this.search_issue = ''
      this.issueEntries = payload.issues
      this.header_issue = [
        /*{
          title: 'ID',
          align: 'start',
          sortable: true,
          key: 'id'
        },*/
        { title: 'Customer', key: 'customer' },
        { title: 'Device', key: 'device_name' },
        { title: 'Issue Description', key: 'notes' },
        { title: 'Reported By', key: 'reported_by' },
        { title: 'Timestamp', key: 'date_time' }
      ]
    })
    EventBus.on('alldevice_return', (payload) => {
      this.customers = payload.customers
    })
    EventBus.on('allCC_return', (payload) => {
      this.cc_sharepoint = payload.share_point_link
      this.cc_fob = payload.fob_link
      this.cc_host = payload.server_ip
      this.cc_type = payload.connection_type
      this.cc_pkid = payload.pkid
      this.cc_richTextContent = payload.ccInfo_rtf
    })
    EventBus.on('cusDevices_return', (payload) => {
      this.loadDevTable = false
      this.search_devices = ''
      this.deviceEntries = payload.devices
      this.deviceTypes = payload.device_types
      this.custCreds = payload.device_creds
      this.columns_devices = [
        {
          label: 'Customer',
          field: 'cust_name'
        },
        {
          label: 'Device Type',
          field: 'dev_type_name'
        },
        {
          label: 'Sub Type',
          field: 'device_sub_type'
        },
        {
          label: 'Device',
          field: 'device_name'
        },
        {
          label: 'Description',
          field: 'device_desc'
        },
        {
          label: 'IP Addr1',
          field: 'device_addr1'
        },
        {
          label: 'Addr1 Type',
          field: 'device_addr1_type'
        },
        {
          label: 'IP Addr2',
          field: 'device_addr2'
        },
        {
          label: 'Addr2 Type',
          field: 'device_addr2_type'
        },
        {
          label: 'Location',
          field: 'device_location'
        }]
      this.header_devices = [
        {
          title: 'Customer',
          align: 'start',
          sortable: true,
          key: 'cust_name'
        },
        { title: 'Device Type', key: 'dev_type_name' },
        { title: 'Sub Type', key: 'device_sub_type' },
        { title: 'Device', key: 'device_name' },
        { title: 'Description', key: 'device_desc' },
        { title: 'IP Addr1', key: 'device_addr1' },
        { title: 'Addr1 Type', key: 'device_addr1_type' },
        { title: 'IP Addr2', key: 'device_addr2' },
        { title: 'Addr2 Type', key: 'device_addr2_type' },
        { title: 'Location', key: 'device_location' }
      ]
    })
    EventBus.on('closeDeviceAddDetail', () => {
      this.isEditingNewDev = false
    })
    EventBus.on('closeDeviceEditDetail', () => {
      this.expanded = []
    })
    EventBus.on('closeDeviceEditDetailUpdated', () => {
      this.expanded = []
      // this.snackbar_msg = 'Record has been updated'
      toast.success('Record has been updated')
      // this.hasSaved = true
      this.$store.dispatch('getCustDevices', { matrix: 'devices', customer: this.selectedCustomer })
    })
    EventBus.on('deleteDevice_returnDeleted', (devId) => {
      this.expanded = []
      this.deviceEntries = this.deviceEntries.filter(d => d.device_id !== devId)
      // this.snackbar_msg = 'Record has been deleted'
      toast.success('Record has been deleted')
      // this.hasSaved = true
    })
    EventBus.on('addDevice_returnAdded', () => {
      this.isEditingNewDev = false
      // this.snackbar_msg = 'Record has been added'
      toast.success('Record has been added')
      // this.hasSaved = true
      this.$store.dispatch('getCustDevices', { matrix: 'devices', customer: this.selectedCustomer })
    })
    EventBus.on('closeCredEditDetail', () => {
      this.expandedCred = []
    })
    EventBus.on('credEditDetailDelete', (creId) => {
      this.expandedCred = []
      this.credEntries = this.credEntries.filter(c => c.pkid !== creId)
      // this.$store.dispatch() ... delete cred entity
    })
    EventBus.on('ticket_note_input', (payload) => {
      this.ticket_note = payload.ticket_note
    })
  },

  created () {
    if (this.custEntries.length < 1) {
      this.$store.dispatch('getCustomerData', { entity: 'customer' })
      this.loadCustomerTable = true
    }

    if (this.customers.length < 1) {
      this.$store.dispatch('getAllDeviceData', { matrix: 'all', customer: '' })
    }
  },

  methods: {
    processTab (argument) {
      this.selectedCustomer = ''
      if (argument == 'Customer') {
        this.getCustomers()
      }
      else if (argument == 'Customer Conn') {
        // this.$store.dispatch('getHcsDeviceData', { matrix: 'hcs', customer: '' })
      }
      else if (argument == 'Device Types') {
        this.getDevTypes()
      }
      else if (argument == 'Credentials') {
        this.getCreds()
      }
      else if (argument == 'Devices') {
        // this.$store.dispatch('getWxFedDeviceData', { matrix: 'wxcce_fed', customer: '' })
      }
      else if (argument == 'Access Logs') {
        this.getAccessLogs()
      }
      else if (argument == 'Audit Logs') {
        this.getAudits()
      }
      else if (argument == 'Issue Report') {
        this.getIssues()
      }
      
    },
    utc2cst (dateValue) {
      var dateJan = new Date(dateValue.getFullYear(), 0, 1)
      var dateJul = new Date(dateValue.getFullYear(), 6, 1)
      var timezoneOffset = Math.max(dateJan.getTimezoneOffset(), dateJul.getTimezoneOffset())
      // Check if daylight savings
      if (dateValue.getTimezoneOffset() < timezoneOffset) {
        // Adjust date by 5 hours
        dateValue = new Date(dateValue.getTime() - ((1 * 60 * 60 * 1000) * 5))
      } else {
        // Adjust date by 6 hours
        dateValue = new Date(dateValue.getTime() - ((1 * 60 * 60 * 1000) * 6))
      }
      return dateValue.format('YYYY-MM-DD HH:mm:ss')
    },
    getCustomers () {
      if (this.custEntries.length < 1) {
        this.$store.dispatch('getCustomerData', { entity: 'customer' })
        this.loadCustomerTable = true
      }
    },
    getDevTypes () {
      if (this.devTypeEntries.length < 1) {
        this.$store.dispatch('getDevTypeData', { entity: 'dev_type' })
        this.loadDevTypeTable = true
      }
    },
    getCreds () {
      //this.loadCredTable = true
      if (this.credEntries.length < 1) {
        // alert('getCredData api call start ..')
        this.$store.dispatch('getCredData', { entity: 'credentials' })
        this.loadCredTable = true
      }
    },
    getAudits () {
      if (this.auditEntries.length < 1) {
        this.$store.dispatch('getAuditData', { entity: 'audit' })
        this.loadAuditLogsTable = true
      }
    },
    getIssues () {
      if (this.issueEntries.length < 1) {
        this.$store.dispatch('getIssueData', { entity: 'issue' })
        this.loadIssuesTable = true
      }
    },
    getAccessLogs () {
      if (this.accessLogEntries.length < 1) {
        this.$store.dispatch('getAccessLogData', { entity: 'access_log' })
        this.loadAccessTable = true
      }
    },
    editCustomer (cust) {
      this.custEditingId = cust.pkid
      this.cust_name_preSave = cust.cust_name
      this.snow_cust_account_preSave = cust.snow_cust_account
      this.em7_active_preSave = cust.em7_active
      this.external_api_enabled_preSave = cust.external_api_enabled
      this.active_preSave = cust.active
    },
    async saveCustomer (cust) {
      if (
        await this.$refs.confirm.open(
          'Confirm',
          'Are you sure to save customer: ' + cust.cust_name + '?'
        )
      ) {
        this.custEditingId = ''
        var curDay = new Date()
        var saveCustEntry = { 'pkid': cust.pkid,
          'cust_name': cust.cust_name,
          'cust_name_preSave': this.cust_name_preSave,
          'snow_cust_account': cust.snow_cust_account,
          'em7_active': cust.em7_active,
          'external_api_enabled': cust.external_api_enabled,
          'active': cust.active,
          'active_preSave': this.active_preSave,
          'ticket_note': this.ticket_note,
          'update_by': this.$store.getters.getLoginUser,
          'time_stamp': curDay.toISOString().slice(0, 10) + ' ' + curDay.toTimeString().split(' ')[0]
        }
        //alert('saveCustomer' + saveCustEntry)
        this.$store.dispatch('saveCustomer', { customer: saveCustEntry })
        this.ticket_note = ''
      }
    },
    cancelSaveCustomer (cust) {
      this.custEditingId = ''
      cust.cust_name = this.cust_name_preSave
      cust.snow_cust_account = this.snow_cust_account_preSave
      cust.em7_active = this.em7_active_preSave
      cust.external_api_enabled = this.external_api_enabled_preSave
      cust.active = this.active_preSave
    },
    async deleteCustomer (cust) {
      this.custEditingId = ''
      // let confirmed = confirm('Are you sure to delete customer: ' + cust.cust_name + '?')
      if (
        await this.$refs.confirm.open(
          'Confirm',
          'Are you sure to delete customer: ' + cust.cust_name + '?'
        )
      ) {
        var curDay = new Date()
        this.$store.dispatch('deleteCustomer', {
          customer: cust,
          ticket_note: this.ticket_note,
          updated_by: this.$store.getters.getLoginUser,
          time_stamp: curDay.toISOString().slice(0, 10) + ' ' + curDay.toTimeString().split(' ')[0] })
        this.ticket_note = ''
      }
    },
    addCustomer () {
      this.isEditingNewCust = true
      this.newCustName = ''
      this.newCustSnowOrgName = ''
      this.newCustEm7Active = 0
      this.newCustExtApiEnabled = 0
      this.newCustAdrActive = 0
      this.newCustNotes = ''
    },
    addNewCust () {
      if (this.newCustName.trim() === '' || this.newCustSnowOrgName.trim() === '') {
        
        this.$refs.confirm.open(
          'Warning',
          'Please enter customer name and corresponding SNOW organization name ...'
        )
      } else {
        var curDay = new Date()
        var newCustEntry = { 'pkid': uuidv4(),
          'cust_name': this.newCustName,
          'snow_cust_account': this.newCustSnowOrgName,
          'em7_active': this.newCustEm7Active,
          'external_api_enabled': this.newCustExtApiEnabled,
          'active': this.newCustAdrActive,
          'time_stamp': curDay.toISOString().slice(0, 10) + ' ' + curDay.toTimeString().split(' ')[0]
        }
        this.custEntries = [...this.custEntries, newCustEntry]
        this.$store.dispatch('addCustomer', { customer: newCustEntry })
        this.isEditingNewCust = false
      }
    },
    cancelNewCust () {
      this.isEditingNewCust = false
    },
    async saveCustCC () {
      if (
        await this.$refs.confirm.open(
          'Confirm',
          'Are you sure to save connectivity info for customer: ' + this.selectedCustomer + '?'
        )
      ) {
        var curDay = new Date()
        var custCC = { 'pkid': this.cc_pkid,
          'type': this.cc_type,
          'host': this.cc_host,
          'sharepoint': this.cc_sharepoint,
          'fob': this.cc_fob,
          'richTextContect': this.cc_richTextContent,
          'time_stamp': curDay.toISOString().slice(0, 10) + ' ' + curDay.toTimeString().split(' ')[0]
        }
        this.$store.dispatch('saveCustCC', { connectivity: custCC })
      }
    },
    changeCustomer (value) {
      this.selectedCustomer = value
      this.$store.dispatch('getAllCustConnInfo', { customer: value })
    },
    changeDevCustomer (value) {
      this.selectedCustomer = value
      this.loadDevTable = true
      this.$store.dispatch('getCustDevices', { matrix: 'devices', customer: value })
    },
    addDevType () {
      this.isEditingNewDevType = true
      this.newTypeName = ''
      this.newTypeSubType = ''
      this.newTypeDesc = ''
    },
    editDevType (type) {
      this.typeEditingId = type.pkid
      this.type_preSave = type.dev_type_name
      this.typeSubType_preSave = type.dev_subtype
      this.typeDesc_preSave = type.dev_description
    },
    saveDevType (type) {
      var curDay = new Date()
      var devTypeEntry = { 'pkid': type.pkid,
        'dev_type_name': type.dev_type_name,
        'dev_subtype': type.dev_subtype,
        'dev_description': type.dev_description,
        'dev_update_by': this.$store.getters.getLoginUser,
        'time_stamp': curDay.toISOString().slice(0, 10) + ' ' + curDay.toTimeString().split(' ')[0]
      }
      this.$store.dispatch('saveDeviceType', { device_type: devTypeEntry })
      this.typeEditingId = ''
      this.$store.dispatch('getDevTypeData', { entity: 'dev_type' })
      this.loadDevTypeTable = true
    },
    cancelSaveDevType (type) {
      this.typeEditingId = ''
      type.dev_type_name = this.type_preSave
      type.dev_subtype = this.typeSubType_preSave
      type.dev_description = this.typeDesc_preSave
    },
    async deleteDevType (type) {
      this.typeEditingId = ''
      if (
        await this.$refs.confirm.open(
          'Confirm',
          'Are you sure to delete device type: ' + type.dev_type_name + '?'
        )
      ) {
        // this.devTypeEntries = this.devTypeEntries.filter(t => t.pkid !== type.pkid)
        this.$store.dispatch('deleteDeviceType', { device_type_id: type.pkid })
        this.$store.dispatch('getDevTypeData', { entity: 'dev_type' })
        this.loadDevTypeTable = true
      }
    },
    addNewDevType () {
      if (this.newTypeName.trim() === '') {
        this.$refs.confirm.open(
          'Warning',
          'Please enter type name ...'
        )
      } else {
        var curDay = new Date()
        var devTypeEntry = { 'pkid': uuidv4(),
          'dev_type_name': this.newTypeName,
          'dev_subtype': this.newTypeSubType,
          'dev_description': this.newTypeDesc,
          'dev_update_by': this.$store.getters.getLoginUser,
          'time_stamp': curDay.toISOString().slice(0, 10) + ' ' + curDay.toTimeString().split(' ')[0]
        }
        this.$store.dispatch('addDeviceType', { device_type: devTypeEntry })
        this.isEditingNewDevType = false
        this.$store.dispatch('getDevTypeData', { entity: 'dev_type' })
        this.loadDevTypeTable = true
      }
    },
    cancelNewDevType () {
      this.isEditingNewDevType = false
    },
    addDevice () {
      this.isEditingNewDev = true
    },
    addCredential () {
      this.isEditingNewCredential = true
      this.newCredUser = ''
      this.newCredPass = ''
      this.newCredPassConfirm = ''
      this.newCredDesc = ''
      this.selectedCredCust = null
    },
    addNewCredential () {
      if (this.selectedCredCust == null || this.newCredUser === '') {
        this.$refs.confirm.open(
          'Warning',
          'Please select customer for credential and input username.'
        )
      } else if (this.newCredPass !== this.newCredPassConfirm) {
        this.$refs.confirm.open(
          'Warning',
          'Input password is not matched with confirm password. Please re-enter.'
        )
      } else {
        var curDay = new Date()
        var devCred = { 'cust_id': this.selectedCredCust.pkid,
          'cred_user': this.newCredUser,
          'cred_pass': this.newCredPass,
          'cred_desc': this.newCredDesc,
          'cred_update_by': this.$store.getters.getLoginUser,
          'time_stamp': curDay.toISOString().slice(0, 10) + ' ' + curDay.toTimeString().split(' ')[0]
        }
        this.$store.dispatch('addDevCred', { credential: devCred })
        this.isEditingNewCredential = false
      }
    },
    cancelNewCredential () {
      this.isEditingNewCredential = false
    },
    showModal () {
      this.isModalVisible = true
    },
    closeModal () {
      this.isModalVisible = false
    },
    clickRow(event, row) {  // This event passes two arguments: the item, and the item slot data, including the index of the clicked row. Use this information to modify the this.expanded array which tracks all expanded rows:
      if (this.expanded.indexOf(row.item.device_id) >= 0) {
          this.expanded.splice(this.expanded.indexOf(row.item.device_id), 1);
      } else {
         this.expanded.push(row.item.device_id)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.ttec_table.v-data-table :deep(th) {
  font-size: 11px !important;
  font-weight: bold;
  background-color: #ede;
}
.ttec_table.v-data-table :deep(td) {
  font-size: 11px !important;
}

.ttec_table.theme--light.v-data-table :deep(tbody tr:nth-of-type(even)) {
    background-color: rgba(0, 0, 0, .03);
}
.ttec_table.theme--dark.v-data-table :deep(tbody tr:nth-of-type(even)) {
    background-color: rgba(0, 0, 0, .5);
}

.raisedbox {
  width: 500px;
  padding: 10px;
  border: 1px solid #0ab7d7;
  box-shadow:  -1px 1px #0ab7d7,
         -2px 2px #0ab7d7,
         -3px 3px #0ab7d7,
         -4px 4px #0ab7d7,
         -5px 5px #0ab7d7;
}

.custom-text-field :deep(.v-field__input) { 
  padding-top: 0; 
  padding-block: 0; 
  margin: 0;
  height: 8px !important;
  font-size: 14px;
  }

</style>

<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <slot name="header"><b>Import Devices</b></slot>
          <div class="container">
            <div class="row mt-2 text-center">
                <div class="col-6 offset-3">
                    <a href="./csv-sample.csv" target="_blank">Example Client Devices CSV for Upload</a>
                </div>
            </div>
            <section class="py-2">
                <div class="row mt-1">
                    <div class="col-12 offset-0">
                        <input type="file" accept=".csv" @change="handleFileUpload( $event )"/>
                        
                        <v-btn v-if="parsed" prepend-icon="mdi-upload" small class="half-margin-left ml-5  ciscoblue" dark @click="uploadDevices()">
                          <template v-slot:prepend>
                            <v-icon color="success"></v-icon>
                          </template>
                          Upload to database
                        </v-btn>
                        <br/>
                        <v-checkbox
                          v-if="parsed"
                          v-model="clearDevBeforeUpoload"
                          hide-details
                          density="compact" 
                          small
                          color="success"
                          label="Clear Devices Before Import">
                        </v-checkbox>
                        <br/>
                        <v-chip v-if="parsed" class="text-small text-bold mb-3 mr-0" size="x-small">Import Data Preview</v-chip>
                        <div v-if="parsed" class="table-container">
                          <v-simple-table density="compact">
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th v-for="(header, key) in content.meta.fields" :key="'header-' + key" style="text-align: left;">
                                    {{ header }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(row, rowKey) in content.data" :key="'row-' + rowKey">
                                  <td v-for="(column, columnKey) in content.meta.fields" :key="'row-' + rowKey + '-column-' + columnKey">
                                    <input v-model="content.data[rowKey][column]" />
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </div>
                    </div>
                </div>
            </section>
          </div>
          <v-btn  prepend-icon="mdi-close" small class="mt-2 ciscoblue" dark @click="clearContent();$emit('close')">
                  <template v-slot:prepend>
                    <v-icon color="success"></v-icon>
                  </template>
                  Close
                </v-btn>
          <v-snackbar
                        v-model="hasUploaded"
                        :timeout="3000"
                        attach
                        position="absolute"
                        location="bottom left"
                        >
                        {{ this.snackbar_notice }}
        </v-snackbar>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { EventBus } from '@/utils'
import Papa from 'papaparse'
import { useToast } from 'vue-toastification'

export default {
  name: 'Modal',
  props: {deviceCust: String},
  components: {
  },
  data () {
    return {
      file: '',
      content: [],
      parsed: false,
      snackbar_notice: '',
      hasUploaded: false,
      clearDevBeforeUpoload: false
    }
  },
  methods: {
    parseFile () {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          this.content = results
          this.parsed = true
        }.bind(this)
      })
    },
    clearContent () {
      this.parsed = false
      this.clearDevBeforeUpoload = false
      this.file = ''
      this.content = []
    },
    handleFileUpload (event) {
      this.file = event.target.files[0]
      this.parseFile()
    },
    uploadDevices () {
      if (this.deviceCust === this.content['data'][0]['customer']) {
        var curDay = new Date()
        this.$store.dispatch('uploadClientDevices',
          { customer: this.deviceCust,
            clearDevBeforeUpoload: this.clearDevBeforeUpoload,
            update_by: this.$store.getters.getLoginUser,
            time_stamp: curDay.toISOString().slice(0, 10) + ' ' + curDay.toTimeString().split(' ')[0],
            payload: this.content })
        this.parsed = false
        this.file = ''
      } else {
        // alert('Customer name in import file is not matched with selected customer. Please correct and try again.')
        const toast = useToast()
        toast.warning('Customer name in import file is not matched with selected customer. Please correct and try again.')
      }
    }
  },
  mounted () {
    EventBus.on('uploadClientDevices_return', (payload) => {
      const toast = useToast()
      if (payload.status === 'OK' || payload.status === 'success') {
        // this.snackbar_notice = 'Client devices had been imported successfully.'
        toast.success('Client devices had been imported successfully.')
      } else {
        // this.snackbar_notice = payload.status
        toast.warning(payload.status)
      }
      // this.hasUploaded = true
    })
  }
}
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .4s ease;
}

button{
  border: none;
  color: #fff;
  padding: 10px;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60%;
  margin: 0px auto;
  padding: 20px 20px;
  min-height: 35px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .2s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

.dragndrop {
      background-color: #f5f5f5;
      height: 50vh;
      width: 80%;
      margin: 2.5em auto;
}


</style>

<template>
  <div class="cui">
    <div class="card card--raised text-center">
        <div class="card__header">
            <v-row no-gutters>
              <v-col class="pl-10 pr-0">
                <a class="text-large" href="javascript:;">{{ deviceName }}</a>
                <div class="text-small ma-0 pb-0">{{ deviceDesc }}</div>
                <img style="height:28px;margin-bottom:0px;padding-bottom:0px;margin-top:3px;padding-top:0px;" src="img/lan-wireless.png">
              </v-col>
              <v-col>
                <v-btn :disabled="report_text==''" prepend-icon="mdi-message-text" x-small class="text-small mb-1 item-icon" dark @click="reportIssue(deviceID)">
                  <template v-slot:prepend>
                    <v-icon color="success"></v-icon>
                  </template>
                  Report
                </v-btn>
                <v-textarea 
                 clearable
                 clear-icon="mdi-close-circle"
                 density="compact" 
                 class="mb-0 pb-0" 
                 label="Enter device information issue detail here .. "
                 rows="1"
                 auto-grow
                 row-height="12"
                 v-model="report_text"
                 >
                </v-textarea>
              </v-col>
            </v-row>
        </div>
        <div class="card__body mt-0 mb-0 pt-0">
            <v-row no-gutters>
              <v-col cols="4">
                <div>
                    <div class="text-bold text-uppercase ">Type</div>
                    <a href="javascript:;">{{ deviceType }} {{ deviceSubType == ''? '':'-'}} {{ deviceSubType }}</a>
                    <div class="text-bold text-uppercase half-margin-top">Primary Address {{ deviceIpType1 == ''? '':'-'}} {{ deviceIpType1 }}</div>
                    <a href="javascript:;">{{ deviceIp1 }}</a>
                    <div class="text-bold text-uppercase half-margin-top">Secondary Address {{ deviceIpType2 == ''? '':'-'}} {{ deviceIpType2 }}</div>
                    <a href="javascript:;">{{ deviceIp2 }}</a>
                    <div class="text-bold text-uppercase half-margin-top">Serial Number</div>
                    <a href="javascript:;">{{ deviceSerialNum }}</a>
                    <div class="text-bold text-uppercase half-margin-top">Device Location</div>
                    <a href="javascript:;">{{ deviceLocation }}</a>
                </div>
              </v-col>
              <v-col cols="8">
                  <v-chip class="mt-0 ml-1 text-bold" size="small"><b>Credential</b></v-chip>
                  <v-data-table
                    :headers="headers_cred"
                    :items="credEntries"
                    density="compact"
                    hide-default-footer
                    disable-pagination
                    item-key="type"
                    class="ml-1"
                  >
                 
                  <template v-slot:[`item.type`]="{ item  }">
                    <div class="flex">
                      <v-text-field class="ma-0 pa-0 ttec_fill" hide-details density="compact" v-model="item.type" label=""></v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.username`]="{ item  }">
                    <div class="flex">
                      <v-text-field class="ma-0 pa-0 ttec_fill" hide-details density="compact" v-model="item.username" label=""></v-text-field>
                    </div>
                  </template>
                  <template v-slot:[`item.password`]="{ item }">
                    <div class="flex">
                      <v-text-field class="ma-0 pa-0" hide-details density="compact" v-model="item.password" label="" :type="show_password ? '' : 'password'"></v-text-field>
                      <v-btn x-small class="mb-3 ml-1" density="compact" :icon="show_password ?  'mdi-eye-off' :'mdi-eye'" @click="toggleHide()"></v-btn>
                    </div>
                  </template>
                  <template #bottom></template>
                  </v-data-table>
                  <v-chip class="mt-3 ml-1 mb-0 text-bold" size="small"><b>Access Info</b></v-chip>
                  <p v-if="this.deviceAccessInfo != ''" class="ttec_left" style="border: 1px solid #888888;border-radius: 5px; margin-top:3px; margin-bottom:0px; background-color: #dddddd; font-size: 1.1em; padding-left: 0.3em;" v-html="this.deviceAccessInfo"></p>
              </v-col>
            </v-row>
        </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils'
import { v4 as uuidv4 } from 'uuid'
import { useToast } from 'vue-toastification';

export default {
  name: 'DeviceDetail',
  props: { 
    deviceID: { type: String},
    deviceName: { type: String},
    deviceDesc: { type: String},
    deviceType: { type: String},
    deviceSerialNum: { type: String},
    deviceLocation: { type: String},
    deviceIp1: { type: String},
    deviceIpType1: { type: String},
    deviceSubType: { type: String},
    deviceIp2: { type: String},
    deviceIpType2: { type: String},
    deviceAccessInfo: { type: String}
  },
  data: () => ({
    show_password: false,
    credEntries: [],
    headers_cred: [],
    report_text: ''
  }),

  created () {
    this.$store.dispatch('getDeviceCredList', { did: this.deviceID })
  },

  methods: {
    toggleHide () {
      this.show_password = !this.show_password
      var curDay = new Date()
      this.$store.dispatch('setCredAccessLog', { did: this.deviceID,
        devicename: this.deviceName,
        user: this.$store.getters.getLoginUser,
        time_stamp: curDay.toISOString().slice(0, 10) + ' ' + curDay.toTimeString().split(' ')[0] })
    },
    reportIssue(devId) {
      if (this.report_text != '') {
        var curDay = new Date()
        // alert('report issue with device id as: ' + devId + ' [' + this.report_text + '] by[' + this.$store.getters.getLoginUid + ']' + dtLbl)
        this.$store.dispatch('addDevInfoIssueRpt', 
            { pkid: uuidv4(),
              did: this.deviceID, 
              notes: this.report_text, 
              reported_by:  this.$store.getters.getLoginUser,
              date_time: curDay.toISOString().slice(0, 10) + ' ' + curDay.toTimeString().split(' ')[0] })
        this.report_text = ''     // reset textarea field
      }
    }
  },
  mounted () {
    this.show_password = false
    
    this.headers_cred = [
      { title: 'Type', value: 'type', sortable: false },
      { title: 'Username', value: 'username', sortable: false },
      { title: 'Password', value: 'password', sortable: false }
    ]

    EventBus.on('device_cred_return', (payload) => {
      this.credEntries = payload.creds
    })

    EventBus.on('addDevInfoIssueRpt_return', (payload) => {
      if (payload.status === 'OK') {
        var toast = useToast()
        toast.success('Thank you for your information. Issue report had been recorded.')
      }
    })
  }
}
</script>

<style lang="css" scoped>
.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.v-text-field :deep(input) {
    font-size: 0.8em;
}

.v-text-field :deep(label) {
    font-size: 0.8em;
}

tbody :deep(tr:nth-of-type(even)) {
    background-color: rgba(236, 237, 237);
}

tbody :deep(tr:nth-of-type(odd)) {
    background-color: rgb(250 ,250, 250);
}

.v-data-table-header {
    background-color: rgba(182, 183, 187);
    color: white;
}

.ttec_fill {
  flex-grow:1;
}
.ttec_left {
  text-align: left;
}

.item-icon :deep(.v-icon) {
    margin-right: 0px;
    padding-bottom: 4px;
    size: x-small;
  } 
</style>

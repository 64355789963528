<template>
  <form name="login-form">
    <div class="mb-3">
      <label for="username">Username: </label>
      <input type="text" id="username" v-model="input.username" />
    </div>
    <div class="mb-3">
      <label for="password">Password: </label>
      <input type="password" id="password" v-model="input.password" />
    </div>
    <button class="btn btn-outline-dark" type="submit" v-on:click.prevent="login()">
      Login
    </button>
  </form>


  <div class="cui bg" >
    <br/>
    <div id="fullPage">
      <div id="contentWrapper" class="float">
        <div id="content">
          <div id="header">
            <img class="logoImage" src="../assets/TTEC_logo.png" alt="TTEC ADR Login" />
          </div>
          <div id="workArea">
            <div id="authArea" class="groupMargin">
              <div id="loginArea">
                <div id="prodName" class="text-large text-bold groupMargin login-notice ttec-text-gap">ADR Portal</div>
                <div id="loginMessage" class="groupMargin login-notice">Sign in using your network credentials</div>
                <v-card-text>
                <v-form v-if="use_ladp">
                  <v-text-field
                    id="login"
                    label="Login"
                    name="login1"
                    v-model="input.username"
                    prepend-icon="mdi-account"
                    append-icon="mdi-user"
                    type="text"
                    density="compact"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    v-model="input.password"
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    density="compact"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions
              class= "justify-center"
              >
                <!-- Login Button  -->
                <div id="submissionArea">
                    <span id="submitButton" v-if="use_ladp" class="mt-0 submit rounded-pill elevation-5"
                        @click="login">Sign in</span>
                        <v-spacer /><br/><br/>
                     <span id="submitButtonSSO" class="mt-3 submit ciscoblue rounded-pill elevation-5"
                        @click="loginSSO">SSO Sign In</span>
                </div>
                
                <v-overlay
                  :absolute="absolute"
                  :opacity=".5"
                  :value="overlay"
                >
                  <v-btn
                    color="red lighten-2"
                    @click="overlay = false"
                  >
                    {{errorMsg}}
                  </v-btn>
                </v-overlay>
              </v-card-actions>
              <div id="introduction" class="groupMargin">
                 <p class='description-notice'>By using ADR, you accept the documented Terms of Services and Privcay Statements.</p>
               </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { EventBus } from '@/utils'
import { onBeforeMount } from 'vue';
import { useToast } from 'vue-toastification';


export default {
  name: 'Login',
  data() {
    return {
      input: {
        username: "",
        password: ""
      },
      output: "",
      show1: false,
      opacity: 1,
      overlay: false,
      errorMsg: '',
      account: undefined,
      use_ladp: false
    }
  },
  created() {
      fetch('/config.json').then(res => res.json()).then(config => {
        this.use_ladp = config['USE_LDAP']
      })
  },
  setup(){
    onBeforeMount(()=>{
      localStorage.clear()
      sessionStorage.clear()
    })
  },
  mounted () {
    this.input.username = ''
    this.input.password = ''
    let self = this
    window.addEventListener('keyup', function (event) {
      if (event.keyCode === 13) {
        self.login()
      }
    })
    EventBus.on('Authenticated_user', (msg) => {
      // alert('Event Buss msg = ' + msg)
      if (!this.$store.getters.isAuthenticated) {
        // alert('Did NOT find Auth User')
        // this.component = Login
        this.$router.push({ path: '/' })
      } else {
        // alert('Found Auth User')
        this.$router.push({ path: '/devices' })
      }
    }),
    EventBus.on('failedAuthentication', msg => {
      //this.errorMsg = msg
      //this.overlay = true
      const toast = useToast()
      toast.error(msg)
      // console.log(msg)
    })
  },
  methods: {
    async loginSSO() {
      try {
        const msal = this.$msal;
        await msal.loginPopup({ scopes: ['User.Read'] })
          .then((response) => {
            const myAccunts = msal.getAllAccounts();
            this.account = myAccunts[0];
          //console.log('[loginSSO] emit event login name ==> ' + this.account.name)
          //console.log('[loginSSO] emit event login username/id ==> ' + this.account.username)
          //console.log('[loginSSO] emit event login localAccountId ==> ' + this.account.localAccountId)
          //console.log('[loginSSO] emit event login idTokenClaims ver ==> ' + this.account.idTokenClaims.ver)
          //console.log('[loginSSO] emit event login idTokenClaims obj ==> ' + JSON.stringify(this.account))
          this.$store.dispatch('loginSSO', {accountinfo: this.account})
          }).catch(error => {
            console.error(`error during authentication: ${error}`)
          });
        } catch (err) {
        console.error("Error during loginRedirect:", err);
      }
    },
    SignOut() {
      /*await this.$msalInstance.logout({}).then(() => {
        console.log('[SignOut] emit event logout ==> ');
      }).catch(error => {
        console.error(error);
      });*/
    },
    login() {
      //make sure username OR password are not empty
      this.$store.dispatch('login', { usrid: this.input.username, password: this.input.password })
    },
  },


}
</script>

<style scoped>
.login_box{
      box-shadow: 5px 5px #b60000,
                4px 4px white,
                3px 3px white,
                2px 2px white,
                1px 1px white,
                5px 5px 5px black;
    border:1px solid white;
}
.bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url( '../assets/img/pattern.jpg') repeat;
}
.ttec-text-gap {
  letter-spacing: 1px !important;
  color: #444444 !important;
}

</style>
<template>
  <h1></h1>
</template>

<script>
  import { onMounted, getCurrentInstance } from 'vue';
  import { useRouter } from "vue-router"
  import { useStore } from 'vuex';

  export default {
    name: 'Authenticate',
    setup() {
      const getAccounts = async () => {
        const router = useRouter()
        const store = useStore()
        const vm = getCurrentInstance()?.proxy;

        try {
          try {
            vm.$msal.handleRedirectPromise();
            const accounts = vm.$msal.getAllAccounts();
            // let account = vm.$msal.getAllAccounts()[0]
            if (accounts.length > 0) {
              //msalInstance.setActiveAccount(accounts[0]);
              let account = vm.$msal.getAllAccounts()[0]
              
              const roles = account.idTokenClaims.roles || [];

              //console.log('[debug]' + account)
              store.dispatch('login', account)
              store.commit('SET_AUTHENTICATION', true);
              store.commit('SET_USERNAME', account.username);
              store.commit('SET_ROLES', roles);
              //console.log("authenticated?", store.getters.isAuthenticated)
              //conconsole.logsole("Authenicaion complee")
              router.push('/devices')    
            }
            else {
              //console.log('accounts.length = 0')
            }
                  
          } catch(e){
            console.log(e)
          }
          
        } catch(e){
          console.log("There was an error logging in.")
          console.log(JSON.stringify(e));
        }
      };

      onMounted(() => {
        getAccounts();
      });

      return {
        getAccounts,
      };
    },
  }
</script>
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './authConfig';
import { EventBus } from '@/utils'

const msalPlugin = {
  async install(app) {
    // Note: EventBus code and this.$router.push in the event handler might need adjustments.
    EventBus.on('Authenticated_user', () => {
      if (!app.config.globalProperties.$store.getters.isAuthenticated) {
        app.config.globalProperties.$router.push({ path: '/' });
      } else {
        app.config.globalProperties.$router.push({ path: '/devices' });
      }
    });
    // Initialize MSAL
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    // await msalInstance.handleRedirectPromise(); 
    // const accounts = msalInstance.getAllAccounts();
    app.config.globalProperties.$msal = msalInstance;

    // if (accounts.length > 0) {
    //   console.log("User is logged in", accounts[0]);
    //   // app.config.globalProperties.$store.commit(`auth/${SET_AUTHENTICATION}`, true);
    //   // app.config.globalProperties.$store.commit(`auth/${SET_USERNAME}`, accounts[0].name);
    //   console.log("Authenticated");
    //   EventBus.emit('Authenticated_user', true /*response.data.authenticated*/)
    //   app.config.globalProperties.$router.push('/devices');
    // } else {
    //   console.log("No accounts found");
    //   EventBus.emit('failedAuthentication', "404 - No accounts found" /*response.data.err_msg*/)
    // }

  }
};

export default msalPlugin;
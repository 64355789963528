import { createWebHistory, createRouter } from "vue-router";

import axios from 'axios';

import MainFrameWork from '../views/MainFrameWork.vue'
import AdrAdmin from '../components/AdrAdmin.vue'
import AdrDevice from '../components/AdrDevice.vue'
import AdrAbout from '../components/AdrAbout.vue'
import AdrDashboard from '../components/AdrDashboard.vue'
import Authenticate from '../components/Authenticate.vue'
import AdrSetMaintenance from "../components/AdrSetMaintenance"
import AdrMaintenanceView from "../components/AdrMaintenanceView"
// import AdrLogin from '../components/Login.vue'

  const routes =  [

    {
      path: '/',
      name: 'MainFrameWork',
      component: MainFrameWork
    },
    {
      path: '/authenticate',
      name: 'Authenticate',
      component: Authenticate
    },
    {
      path: '/admin',
      name: 'AdrAdmin',
      component: AdrAdmin
    },
    {
      path: '/devices',
      name: 'AdrDevice',
      component: AdrDevice
    },
    {
      path: '/about',
      name: 'AdrAbout',
      component: AdrAbout
    },
    {
      path: '/dashboard',
      name: 'AdrDashboard',
      component: AdrDashboard
    },
    {
      path: '/setupmaint',
      name: 'AdrSetMaintenance',
      component: AdrSetMaintenance
    },
    {
      path: '/maintenance',
      name: 'AdrMaintenanceView',
      component: AdrMaintenanceView
    }
    /*
  {
    path: "/",
    component: () => import('../components/Login.vue'),
  },
  {
    path: "/devices",
    component: () => import('../components/WelcomeView.vue'),
  },*/

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  linkActiveClass: 'active',
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({behavior:'smooth'});
  }
})


// Navigation guard for backend 401 response
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      router.push({ path: '/' });
    }
    return Promise.reject(error);
  }
);



export default router;
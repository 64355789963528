<template>
  <div class="cui">
    <div class="card card--raised text-center">
        <div class="card__body mt-0 mb-0">
            <ConfirmDlg ref="confirm" />
            <v-row no-gutters>
              <v-col cols="4">
                <div>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">Device Name:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" text-xsmall hide-details density="compact" :modelValue="deviceName" @update:modelValue="deviceName = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">Description:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" text-xsmall hide-details density="compact" :modelValue="deviceDesc" @update:modelValue="deviceDesc = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">Type / SubType:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-autocomplete
                              :items="deviceTypes"
                              item-title="type"
                              item-value="pkid"
                              hide-details
                              density="compact"
                              :modelValue="deviceTypeId"
                              @update:modelValue="changeDeviceType"
                              class="pb-1"
                              >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP Addr1 Type:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" text-xsmall hide-details density="compact" :modelValue="deviceIpType1"  @update:modelValue="deviceIpType1 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP1:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" text-xsmall hide-details density="compact" :modelValue="deviceIp1" @update:modelValue="deviceIp1 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP Addr2 Type:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" text-xsmall hide-details density="compact" :modelValue="deviceIpType2" @update:modelValue="deviceIpType2 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP2:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" text-xsmall hide-details density="compact" :modelValue="deviceIp2" @update:modelValue="deviceIp2 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP Addr3 Type:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" text-xsmall hide-details density="compact" :modelValue="deviceIpType3" @update:modelValue="deviceIpType3 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold  text-small half-margin-top" style="float:right; margin-right:10px;">IP3:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" text-xsmall hide-details density="compact" :modelValue="deviceIp3" @update:modelValue="deviceIp3 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP Addr4 Type:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" hide-details density="compact" :modelValue="deviceIpType4" @update:modelValue="deviceIpType4 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">IP4:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" text-xsmall hide-details density="compact" :modelValue="deviceIp4" @update:modelValue="deviceIp4 = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">Serial Number:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" text-xsmall hide-details density="compact" :modelValue="deviceSerialNum" @update:modelValue="deviceSerialNum = $event"></v-text-field>
                        </v-col>
                    </v-row>
                  <v-row no-gutters align="center">
                        <v-col cols="4">
                            <div class="text-bold text-small half-margin-top" style="float:right; margin-right:10px;">Device Location:</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" text-xsmall hide-details density="compact" :modelValue="deviceLocation" @update:modelValue="deviceLocation = $event"></v-text-field>
                        </v-col>
                   </v-row>
                    <div class="mt-3">
                            <v-spacer></v-spacer>
                        
                            <v-btn elevation="10" prepend-icon="mdi-content-save" class="ml-0" small dark color="ciscoblue" @click="saveDevice">
                                <template v-slot:prepend>
                                    <v-icon color="success"></v-icon>
                                </template>
                                Save
                            </v-btn>
                            <v-btn elevation="10" prepend-icon="mdi-close-box" class="ml-3" small dark color="ciscoblue" @click="cancelChgDevice">
                                <template v-slot:prepend>
                                    <v-icon color="success"></v-icon>
                                </template>
                                Cancel
                            </v-btn>
                            <v-btn elevation="10" prepend-icon="mdi-trash-can-outline" class="ml-3" small dark color="ciscoblue" @click="deleteDevice(deviceID)">
                                <template v-slot:prepend>
                                    <v-icon color="success"></v-icon>
                                </template>
                                Delete
                            </v-btn>
                    </div>
                </div>
              </v-col>
              <v-col cols="8">
                  <v-row no-gutters>
                      <v-col cols="4">
                        <div class="card__header half-margin-top">
                            <a class="text-large ma-0" href="javascript:;">{{ deviceName }}</a>
                            <div class="text-small ma-0">{{ deviceDesc }}</div>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <div class="pt-2 ma-0 pa-0">
                            <img style="height:30px" src="img/lan-wireless.png">
                        </div>
                      </v-col>
                  </v-row>
                   <v-row no-gutters>
                      <v-col>
                            <v-chip class="mt-0 ml-0 text-bold" size="small">Credential</v-chip>
                            <v-row no-gutters>
                                    <v-col cols="8">
                                    <v-row no-gutters>
                                        <v-col cols="5">
                                            <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">Credential - 1</v-chip>
                                        </v-col>
                                        <v-col cols="7">
                                            <v-autocomplete
                                                :items="custCreds"
                                                item-title="user_pass"
                                                item-value="pkid"
                                                hide-details
                                                density="compact"
                                                :modelValue="cred1_id"
                                                @update:modelValue="changeCred1"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-row no-gutters>
                                            <v-col cols="6">
                                                <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">C1 Usage</v-chip>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field class="pb-1" hide-details density="compact" placeholder="Enter..." :modelValue="cred1_usage" @update:modelValue="cred1_usage = $event"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="8">
                                    <v-row no-gutters justify="center">
                                        <v-col cols="5">
                                            <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">Credential - 2</v-chip>
                                        </v-col>
                                        <v-col cols="7">
                                            <v-autocomplete
                                            :items="custCreds"
                                            item-title="user_pass"
                                            item-value="pkid"
                                            hide-details
                                            density="compact"
                                            :modelValue="cred2_id"
                                            @update:modelValue="changeCred2"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-row no-gutters>
                                            <v-col cols="6">
                                                <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">C2 Usage</v-chip>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field class="pb-1" hide-details density="compact" placeholder="Enter..." :modelValue="cred2_usage" @update:modelValue="cred2_usage = $event"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="8">
                                    <v-row no-gutters>
                                        <v-col cols="5">
                                            <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">Credential - 3</v-chip>
                                        </v-col>
                                        <v-col cols="7">
                                            <v-autocomplete
                                            :items="custCreds"
                                            item-title="user_pass"
                                            item-value="pkid"
                                            hide-details
                                            density="compact"
                                            :modelValue="cred3_id"
                                            @update:modelValue="changeCred3"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-row no-gutters>
                                            <v-col cols="6">
                                                <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">C3 Usage</v-chip>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field class="pb-1" hide-details density="compact" placeholder="Enter..." :modelValue="cred3_usage" @update:modelValue="cred3_usage = $event"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="8">
                                    <v-row no-gutters>
                                        <v-col cols="5">
                                            <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">Credential - 4</v-chip>
                                        </v-col>
                                        <v-col cols="7">
                                            <v-autocomplete
                                            :items="custCreds"
                                            item-title="user_pass"
                                            item-value="pkid"
                                            hide-details
                                            density="compact"
                                            :modelValue="cred4_id"
                                            @update:modelValue="changeCred4"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-row no-gutters>
                                            <v-col cols="6">
                                                <v-chip class="text-xsmall text-bold half-margin-top" size="x-small">C4 Usage</v-chip>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-text-field class="pb-1" hide-details density="compact" placeholder="Enter..." :modelValue="cred4_usage" @update:modelValue="cred4_usage = $event"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                            </v-row>
                      </v-col>
                   </v-row>
                   <div>
                        <v-chip class="mt-5 mb-1 text-bold" size="small">Access Info</v-chip>
                        <ckeditor class="pl-5" :modelValue="dev_richTextContent" @update:modelValue="dev_richTextContent = $event"></ckeditor>
                        <v-spacer></v-spacer>
                        HTML Coding: {{dev_richTextContent}}
                    </div>
              </v-col>
            </v-row>
        </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils'
import ConfirmDlg from './ConfirmDlg'
import { useToast } from 'vue-toastification'

export default {
  name: 'DeviceEditDetail',
  components: {
    ConfirmDlg
  },
  props: { 
    deviceID: String,
    deviceName: String,
    deviceDesc: String,
    deviceType: String,
    deviceSerialNum: String,
    deviceLocation: String,
    deviceIp1: String,
    deviceIpType1: String,
    deviceSubType: String,
    deviceIp2: String,
    deviceIpType2: String,
    deviceIp3: String,
    deviceIpType3: String,
    deviceIp4: String,
    deviceIpType4: String,
    deviceTypeId: String,
    deviceTypes: Array,
    custCreds: Array,
    dev_richTextContent: String
  },
  data: () => ({
    show_password: false,
    credEntries: [],
    headers_cred: [],
    custCreds: [],
    cred1_id: '',
    cred1_usage: '',
    cred2_id: '',
    cred2_usage: '',
    cred3_id: '',
    cred3_usage: '',
    cred4_id: '',
    cred4_usage: '',
    ticket_note: ''
  }),

  created () {
    this.$store.dispatch('getDeviceCredList', { did: this.deviceID })
  },

  methods: {
    toggleHide () {
      this.show_password = !this.show_password
    },
    saveDevice () {
      var curDay = new Date()
      var devEntry = {
        'dev_id': this.deviceID,
        'dev_name': this.deviceName,
        'dev_type': this.deviceTypeId,
        'dev_description': this.deviceDesc,
        'dev_ip1': this.deviceIp1,
        'dev_ipType1': this.deviceIpType1,
        'dev_ip2': this.deviceIp2,
        'dev_ipType2': this.deviceIpType2,
        'dev_ip3': this.deviceIp3,
        'dev_ipType3': this.deviceIpType3,
        'dev_ip4': this.deviceIp4,
        'dev_ipType4': this.deviceIpType4,
        'dev_credId1': this.cred1_id,
        'dev_credUsage1': this.cred1_usage,
        'dev_credId2': this.cred2_id,
        'dev_credUsage2': this.cred2_usage,
        'dev_credId3': this.cred3_id,
        'dev_credUsage3': this.cred3_usage,
        'dev_credId4': this.cred4_id,
        'dev_credUsage4': this.cred4_usage,
        'dev_sn': this.deviceSerialNum,
        'dev_location': this.deviceLocation,
        'dev_richTextContent': this.dev_richTextContent,
        'dev_update_by': this.$store.getters.getLoginUser,
        'time_stamp': curDay.toISOString().slice(0, 10) + ' ' + curDay.toTimeString().split(' ')[0]
      }
      if (this.deviceName.trim() !== '') {
        this.$store.dispatch('saveDevice', { device: devEntry })
      } else {
        // alert('Device name cannot be blank..')
        const toast = useToast()
        toast.warning('Device name cannot be blank..')
      }
    },
    async deleteDevice (devId) {
      if (
        await this.$refs.confirm.open(
          'Confirm',
          'Are you sure to delete this device with id as: ' + devId + '?'
        )
      ) {
        var curDay = new Date()
        this.$store.dispatch('deleteDevice', { device_id: devId,
          'dev_update_by': this.$store.getters.getLoginUser,
          'ticket_note': this.ticket_note,
          'time_stamp': curDay.toISOString().slice(0, 10) + ' ' + curDay.toTimeString().split(' ')[0]  
           })
        this.ticket_note = ''
      }
    },
    cancelChgDevice () {
      EventBus.emit('closeDeviceEditDetail')
    },
    changeDeviceType (value) {
      this.deviceTypeId = value
    },
    changeCred1 (value) {
      this.cred1_id = value
    },
    changeCred2 (value) {
      this.cred2_id = value
    },
    changeCred3 (value) {
      this.cred3_id = value
    },
    changeCred4 (value) {
      this.cred4_id = value
    }
  },
  mounted () {
    this.show_password = false
    // this.credEntries = [{ 'type': 'APP', 'username': 'admin', 'password': 'password345!' },
    //  { 'type': 'SSH', 'username': 'root', 'password': 'password345!' },
    //  { 'type': 'WEB', 'username': 'appuser', 'password': 'password345!' }]
    this.headers_cred = [
      { title: 'Type', align: 'start', sortable: true, value: 'type' },
      { title: 'Username', value: 'username', sortable: false },
      { title: 'Password', value: 'password', sortable: false }
    ]
    EventBus.on('ticket_note_input', (payload) => {
      this.ticket_note = payload.ticket_note
    })
    EventBus.on('saveDevice_return', (payload) => {
      if (payload.status === 'OK') {
        EventBus.emit('closeDeviceEditDetailUpdated')
      }
    })
    EventBus.on('deleteDevice_return', (payload) => {
      if (payload.status === 'OK') {
        EventBus.emit('deleteDevice_returnDeleted', payload.dev_id)
      }
    })
    EventBus.on('device_cred_return', (payload) => {
      this.credEntries = payload.creds
      if (payload.device_creds.fkid_credentials_1 !== '' && payload.device_creds.device_credential1_description !== '') {
        this.cred1_id = payload.device_creds.fkid_credentials_1
        this.cred1_usage = payload.device_creds.device_credential1_description
      }
      if (payload.device_creds.fkid_credentials_2 !== '' && payload.device_creds.device_credential2_description !== '') {
        this.cred2_id = payload.device_creds.fkid_credentials_2
        this.cred2_usage = payload.device_creds.device_credential2_description
      }
      if (payload.device_creds.fkid_credentials_3 !== '' && payload.device_creds.device_credential3_description !== '') {
        this.cred3_id = payload.device_creds.fkid_credentials_3
        this.cred3_usage = payload.device_creds.device_credential3_description
      }
      if (payload.device_creds.fkid_credentials_4 !== '' && payload.device_creds.device_credential4_description !== '') {
        this.cred4_id = payload.device_creds.fkid_credentials_4
        this.cred4_usage = payload.device_creds.device_credential4_description
      }
    })
  }
}
</script>

<style lang="css" scoped>
.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.v-text-field :deep(input) {
    font-size: 0.8em;
}

.v-text-field :deep(label) {
    font-size: 0.8em;
}

tbody :deep(tr:nth-of-type(even)){
    background-color: rgba(236, 237, 237);
}

tbody :deep(tr:nth-of-type(odd)) {
    background-color: rgb(250 ,250, 250);
}

.v-data-table-header {
    background-color: rgba(182, 183, 187);
    color: white;
}
</style>

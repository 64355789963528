import {
    SET_AUTHENTICATION,
    SET_USERNAME,
    SET_ROLES
} from "../storeconstants";

export default {
    [SET_AUTHENTICATION](state, authenticated) {
        state.authenticated = authenticated
    },
    [SET_USERNAME](state, username) {
        state.username = username
    },
    [SET_ROLES](state, roles) {
        state.roles = roles
    },
}
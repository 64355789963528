import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Toast from 'vue-toastification'

import msalPlugin from './plugins/msalPlugin';

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vue-toastification/dist/index.css';

require('./assets/css/cui-basic.min.css')
require('./assets/css/cui-standard.min.css')
require('./assets/css/cui-styleguide.min.css')
require('./assets/css/cui-login.css')

import vuetify from './plugins/vuetify'

/*
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
}) */



// import VueMeta from 'vue-meta'
import CKEditor from '@mayasabha/ckeditor4-vue3'

//import Vuetify from 'vuetify/lib'
//const vuetify = new Vuetify({})




const app = createApp(App)

//app.config.globalProperties.$msalInstance = {}

app.use(msalPlugin);
app.use(store)
app.use(router)
app.use(vuetify)
app.use(CKEditor)
app.use(Toast)
// app.use(VueMeta)

app.mount('#app')

<template>
  <div class="cui">
    <div class="card card--raised text-center">
        <div class="card__body mt-0 mb-0">
              <ConfirmDlg ref="confirm" />
              <v-col>
                    <v-row no-gutters>
                        <v-col cols="4">
                            <div class="text-bold text-uppercase half-margin-top">Username (Case Sensitive)</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" hide-details density="compact" :modelValue="credUserName" @update:modelValue="credUserName = $event"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="4">
                            <div class="text-bold text-uppercase half-margin-top">Password (Case Sensitive)</div>
                        </v-col>
                        <v-col cols="6">
                           <v-row dense>
                              <v-text-field class="pb-1" hide-details density="compact"  :type="show1 ? 'text' : 'password'" :modelValue="credPassword" @update:modelValue="credPassword = $event"></v-text-field>
                              <v-btn ml-0 icon density="compact" title="hide/show" @click="show1 = !show1" >
                                <v-icon
                                  medium
                                  color="blue darken-2"
                                >
                                  {{ show1 ? 'mdi-eye' : 'mdi-eye-off' }}
                                </v-icon>
                              </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="4">
                            <div class="text-bold text-uppercase half-margin-top">Confirm Password (Case Sensitive)</div>
                        </v-col>
                        <v-col cols="6">
                            <v-row dense>
                              <v-text-field class="pb-1" hide-details density="compact" :type="show2 ? 'text' : 'password'" v-model="credPasswordConfirm"></v-text-field>
                              <v-btn ml-0 icon density="compact" title="hide/show" @click="show2 = !show2" >
                                <v-icon
                                  medium
                                  color="blue darken-2"
                                >
                                  {{ show2 ? 'mdi-eye' : 'mdi-eye-off' }}
                                </v-icon>
                              </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="4">
                            <div class="text-bold text-uppercase half-margin-top">Description</div>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field class="pb-1" hide-details density="compact" :modelValue="credDesc" @update:modelValue="credDesc = $event"></v-text-field>
                        </v-col>
                    </v-row>
              </v-col>
        </div>
        <div>
            <v-spacer></v-spacer>
            <v-btn elevation="10" prepend-icon="mdi-content-save" class="ml-0" small dark color="ciscoblue" @click="saveCred">
                <template v-slot:prepend>
                    <v-icon color="success"></v-icon>
                </template>
                Save
            </v-btn>
            <v-btn elevation="10" prepend-icon="mdi-close-box" class="ml-3" small dark color="ciscoblue" @click="cancelChgCred">
                <template v-slot:prepend>
                    <v-icon color="success"></v-icon>
                </template>
                Cancel
            </v-btn>
            <v-btn elevation="10" prepend-icon="mdi-trash-can-outline" class="ml-3" small dark color="ciscoblue" @click="deleteCred(credID)">
                <template v-slot:prepend>
                    <v-icon color="success"></v-icon>
                </template>
                Delete
            </v-btn>
       </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils'
import ConfirmDlg from './ConfirmDlg'
import { useToast } from 'vue-toastification'

export default {
  name: 'CredEditDetail',
  components: {
    ConfirmDlg
  },
  props: { 
    credID: { type: String},
    credUserName: { type: String},
    credPassword: { type: String},
    credDesc: { type: String},
    credCust: { type: String}
  },
  data: () => ({
    credPasswordConfirm: '',
    show1: false,
    show2: false
  }),

  methods: {
    saveCred () {
      if (this.credPasswordConfirm === this.credPassword && this.credUserName.trim() !== '') {
        var curDay = new Date()
        var devCred = {
          'pkid': this.credID,
          'username': this.credUserName,
          'password': this.credPassword,
          'description': this.credDesc,
          'credCust': this.credCust,
          'updatedBy': this.$store.getters.getLoginUser,
          'time_stamp': curDay.toISOString().slice(0, 10) + ' ' + curDay.toTimeString().split(' ')[0]
        }
        this.$store.dispatch('saveDevCred', { device_cred: devCred })
        EventBus.emit('closeCredEditDetail')
      } else {
        // alert('Password not matched or username is blank. Please correct and try again.')
        const toast = useToast()
        toast.error('Password not matched or username is blank. Please correct and try again.')
      }
    },
    cancelChgCred () {
      EventBus.emit('closeCredEditDetail')
    },
    async deleteCred (credId) {
      if (
        await this.$refs.confirm.open(
          'Confirm',
          'Are you sure to delete this [' + this.credCust + '] credential with id as: ' + credId + '?'
        )
      ) {
        // this.devTypeEntries = this.devTypeEntries.filter(t => t.pkid !== type.pkid)
        this.$store.dispatch('deleteDevCred', { device_cred_id: credId })
        EventBus.emit('credEditDetailDelete', credId)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}
.v-text-field :deep(input) {
    font-size: 0.8em;
}

.v-text-field :deep(label) {
    font-size: 0.8em;
}

tbody :deep(tr:nth-of-type(even)) {
    background-color: rgba(236, 237, 237);
}

tbody :deep(tr:nth-of-type(odd)) {
    background-color: rgb(250 ,250, 250);
}

.v-data-table-header {
    background-color: rgba(182, 183, 187);
    color: white;
}
</style>

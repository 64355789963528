<template>
    <v-footer
      class="justify-center pl-0"
      color="#0ab7d7"
      inset
      app
    >
      <v-spacer />
      <div style="font-size: 1.3em;">Copyright &copy; 2022-{{ new Date().getFullYear() }}.  All rights reserved. For internal TTEC Digital use only.</div>
    </v-footer>
</template>

<script>
export default ({
  name: 'Footer'
})
</script>

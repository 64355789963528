<template>
  <v-container class="container">
      <keep-alive>
        <component v-bind:is="component" v-on:toggle2="toggle2"/>
      </keep-alive>
  </v-container>
</template>

<script>
import Login from '../components/Login'
import Signup from '../components/Signup'
// import AdrMainFrameWork from '../components/AdrMainFrameWork'
// import store from '@/store'
import { EventBus } from '@/utils'

export default {
  components: {
    Login,
    Signup
  },
  data () {
    return {
      component: Login
    }
  },
  methods: {
    toggle2 () {
      // console.log('Toggle Hit')
      if (this.component === Login) {
        // console.log('load login component')
        this.component = Signup
      } else {
        // console.log('load Signup component')
        this.component = Login
      }
    },
    AuthValid () {
      if (!this.$store.getters.isAuthenticated) {
        // console.log('Didnt find token, you need to auth')
        this.component = Login
      } else {
        // console.log('Found token, lets try')  // background-image: url('~@/assets/spaceballs.jpg');
        // this.component = AdrMainFrameWork
      }
    }
  },
  mounted () {
    EventBus.on('Authenticated_user', (msg) => {
      // alert('Event Buss msg = ' + msg)
      if (!this.$store.getters.isAuthenticated) {
        // alert('Did NOT find Auth User')
        this.component = Login
      } else {
        // alert('Found Auth User')
        this.$router.push({ path: '/devices' })
      }
    })
    EventBus.on('Logout', (msg) => {
      // console.log('EventBus Log Out')
      this.component = Login
    })
    this.AuthValid()
  }
}

</script>

<style>
.container {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>